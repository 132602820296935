import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BusinessOverview } from './BusinessOverview';
import { BusinessAnalytics } from './BusinessAnalytics';
import { BusinessWorkers } from './BusinessWorkers';
import { BusinessBilling } from './BusinessBilling';
import { BusinessTasks } from './BusinessTasks';
import { TaskTracker } from './TaskTracker';
import { TaskSubmissions } from './TaskSubmissions';

export const BusinessDashboard = () => {
  return (
    <Routes>
      <Route index element={<BusinessOverview />} />
      <Route path="tasks" element={<BusinessTasks />} />
      <Route path="tasks/:taskId/track" element={<TaskTracker />} />
      <Route path="submissions" element={<TaskSubmissions />} />
      <Route path="workers" element={<BusinessWorkers />} />
      <Route path="analytics" element={<BusinessAnalytics />} />
      <Route path="billing" element={<BusinessBilling />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};