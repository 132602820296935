import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, DollarSign, BarChart2, Tag, AlertCircle, CheckCircle } from 'lucide-react';
import { Task } from '../../types/task';
import { Button } from '../shared';
import { useAuth0 } from '@auth0/auth0-react';

interface TaskCardProps {
  task: Task;
  onAccept?: (taskId: string) => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task, onAccept }) => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty) {
      case 'beginner':
        return 'bg-green-100 text-green-800';
      case 'intermediate':
        return 'bg-yellow-100 text-yellow-800';
      case 'expert':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      case 'under_review':
        return 'bg-yellow-100 text-yellow-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="h-4 w-4" />;
      case 'under_review':
        return <Clock className="h-4 w-4" />;
      default:
        return null;
    }
  };

  const handleClick = () => {
    const userType = user?.['https://tocash.io/user_type'];
    if (userType === 'business') {
      navigate(`/dashboard/tasks/${task.id}/track`);
    } else if (task.status === 'in_progress') {
      navigate(`/dashboard/tasks/${task.id}/workspace`);
    }
  };

  const handleAccept = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onAccept) {
      onAccept(task.id);
    }
  };

  const getTimeRemaining = () => {
    const deadline = new Date(task.deadline);
    const now = new Date();
    const diff = deadline.getTime() - now.getTime();
    
    if (diff <= 0) return 'Expired';
    
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    
    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
    
    if (hours > 0) {
      return `${hours}h ${minutes}m left`;
    }
    
    return `${minutes}m left`;
  };

  const isExpired = new Date(task.deadline) < new Date();
  const timeRemaining = getTimeRemaining();
  const isUrgent = !isExpired && new Date(task.deadline).getTime() - new Date().getTime() <= 3600000; // 1 hour

  return (
    <div 
      className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-6 ${
        task.status === 'in_progress' ? 'border-2 border-indigo-500' : ''
      } ${
        ['under_review', 'completed', 'rejected'].includes(task.status) ? 'cursor-default' : 'cursor-pointer'
      }`}
      onClick={['under_review', 'completed', 'rejected'].includes(task.status) ? undefined : handleClick}
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold text-gray-900">{task.title}</h3>
          <div className="flex gap-2 mt-2">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              {task.category.replace('_', ' ')}
            </span>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getDifficultyColor(task.difficulty)}`}>
              {task.difficulty}
            </span>
            {task.status !== 'open' && (
              <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(task.status)}`}>
                {getStatusIcon(task.status)}
                {task.status.replace(/_/g, ' ')}
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-1 text-green-600">
          <DollarSign className="h-5 w-5" />
          <span className="font-semibold">${task.reward}</span>
        </div>
      </div>
      
      <p className="text-gray-600 mb-4 line-clamp-2">{task.description}</p>
      
      <div className="flex items-center justify-between mt-4 pt-4 border-t">
        <div className="flex items-center gap-4 text-sm text-gray-500">
          <div className="flex items-center gap-1">
            <Clock className="h-4 w-4" />
            <span>{task.timeEstimate}</span>
          </div>
          {!isExpired && task.status !== 'completed' && (
            <div className={`flex items-center gap-1 ${
              isUrgent ? 'text-red-600' : 'text-gray-500'
            }`}>
              {isUrgent && <AlertCircle className="h-4 w-4" />}
              <span>{timeRemaining}</span>
            </div>
          )}
        </div>

        {user?.['https://tocash.io/user_type'] === 'business' ? (
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClick}
          >
            Track Progress
          </Button>
        ) : (
          <>
            {task.status === 'open' && onAccept && !isExpired && (
              <Button
                variant="primary"
                size="sm"
                onClick={handleAccept}
              >
                Accept Task
              </Button>
            )}
            
            {task.status === 'in_progress' && (
              <Button
                variant="secondary"
                size="sm"
                onClick={handleClick}
              >
                Continue Task
              </Button>
            )}

            {task.status === 'under_review' && (
              <span className="text-sm text-yellow-600 font-medium">
                Under Review
              </span>
            )}

            {task.status === 'completed' && (
              <span className="text-sm text-green-600 font-medium">
                Completed
              </span>
            )}

            {task.status === 'rejected' && (
              <span className="text-sm text-red-600 font-medium">
                Rejected
              </span>
            )}

            {isExpired && task.status === 'open' && (
              <span className="text-sm text-red-600 font-medium">
                Task Expired
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};