import React from 'react';
import {
  Header,
  Hero,
  Features,
  Statistics,
  HowItWorks,
  ForBusiness,
  Pricing,
  TrustSecurity,
  Community,
  BlogPreview,
  FAQ,
  CTA,
  Footer
} from '../components';

export const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white flex flex-col">
      <Header />
      <main className="flex-1">
        <Hero />
        <Features />
        <Statistics />
        <HowItWorks />
        <ForBusiness />
        <Pricing />
        <TrustSecurity />
        <Community />
        <BlogPreview />
        <FAQ />
        <CTA />
      </main>
      <Footer />
    </div>
  );
};