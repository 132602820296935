import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserCheck, Building2 } from 'lucide-react';
import { useNotifications } from '../../context/NotificationContext';

export const UserTypeSelection = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotifications();

  const handleTypeSelection = async (type: 'individual' | 'business') => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tocash.io',
          scope: 'update:users'
        }
      });

      const response = await fetch('https://api.tocash.io/api/users/type', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userType: type })
      });

      if (!response.ok) {
        throw new Error('Failed to update user type');
      }

      addNotification({
        type: 'success',
        title: 'Account Setup Complete',
        message: 'Your account type has been set successfully'
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to set user type:', error);
      addNotification({
        type: 'error',
        title: 'Setup Failed',
        message: 'Failed to set account type. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-bold text-gray-900">
          Welcome to ToCash
        </h2>
        <p className="mt-2 text-center text-lg text-gray-600">
          Choose how you want to use our platform
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div className="grid md:grid-cols-2 gap-6 px-4">
          <button
            onClick={() => handleTypeSelection('individual')}
            disabled={isLoading}
            className="h-auto p-8 bg-white hover:bg-gray-50 text-left flex flex-col items-start rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <div className="bg-indigo-50 w-16 h-16 rounded-2xl flex items-center justify-center mb-6">
              <UserCheck className="h-8 w-8 text-indigo-600" />
            </div>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              I want to Complete Tasks
            </h3>
            
            <ul className="space-y-3 mb-6">
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Complete tasks and earn money
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Flexible working hours
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Get paid via multiple methods
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Level up and earn more
              </li>
            </ul>
          </button>

          <button
            onClick={() => handleTypeSelection('business')}
            disabled={isLoading}
            className="h-auto p-8 bg-white hover:bg-gray-50 text-left flex flex-col items-start rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <div className="bg-indigo-50 w-16 h-16 rounded-2xl flex items-center justify-center mb-6">
              <Building2 className="h-8 w-8 text-indigo-600" />
            </div>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              I want to Post Tasks
            </h3>
            
            <ul className="space-y-3 mb-6">
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Access global workforce
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Quality assured results
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Scale your operations
              </li>
              <li className="flex items-center gap-2 text-gray-600">
                <span className="h-1.5 w-1.5 rounded-full bg-indigo-600 flex-shrink-0"></span>
                Dedicated support team
              </li>
            </ul>
          </button>
        </div>

        {isLoading && (
          <div className="mt-4 text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            <p className="mt-2 text-gray-600">Setting up your account...</p>
          </div>
        )}
      </div>
    </div>
  );
};