import { SocialMediaActionType } from '../types/task';

export const SOCIAL_MEDIA_PRICING = {
  actions: {
    follow: {
      basePrice: 0.10,
      tokens: 1
    },
    like: {
      basePrice: 0.03,
      tokens: 0.5
    },
    comment: {
      basePrice: 0.15,
      tokens: 1.5
    },
    share: {
      basePrice: 0.20,
      tokens: 2
    },
    click_through: {
      basePrice: 0.25,
      tokens: 2.5
    }
  },
  subscriptionPlans: {
    starter: {
      name: 'Starter',
      monthlyPrice: 50,
      tokens: 500,
      extraTokenPrice: 0.10,
      description: 'Perfect for small businesses starting with social media engagement'
    },
    growth: {
      name: 'Growth',
      monthlyPrice: 100,
      tokens: 1100,
      extraTokenPrice: 0.09,
      description: 'Ideal for growing businesses with moderate engagement needs'
    },
    pro: {
      name: 'Pro',
      monthlyPrice: 200,
      tokens: 2400,
      extraTokenPrice: 0.08,
      description: 'Best value for businesses with high engagement requirements'
    },
    enterprise: {
      name: 'Enterprise',
      monthlyPrice: null, // Custom pricing
      tokens: null, // Custom amount
      extraTokenPrice: null, // Negotiable
      description: 'Custom solutions for large-scale campaigns'
    }
  },
  bulkDiscounts: [
    {
      minTokens: 1000,
      discount: 0.05 // 5% discount
    },
    {
      minTokens: 5000,
      discount: 0.10 // 10% discount
    },
    {
      minTokens: 10000,
      discount: 0.15 // 15% discount
    }
  ],
  loyaltyDiscounts: {
    threemonths: 0.05, // 5% discount after 3 months
    sixmonths: 0.10, // 10% discount after 6 months
    year: 0.15 // 15% discount after 1 year
  }
};

export const getPlanDiscount = (
  plan: keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans
): number => {
  switch (plan) {
    case 'growth':
      return 0.05;
    case 'pro':
      return 0.10;
    case 'enterprise':
      return 0.15;
    default:
      return 0;
  }
};

export const getBulkDiscount = (quantity: number): number => {
  const discount = SOCIAL_MEDIA_PRICING.bulkDiscounts
    .reverse()
    .find(d => quantity >= d.minTokens);
  return discount ? discount.discount : 0;
};

export const calculateTaskCost = (
  actionType: SocialMediaActionType,
  quantity: number,
  plan: keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans
): number => {
  const action = SOCIAL_MEDIA_PRICING.actions[actionType];
  if (!action) return 0;

  const baseTotal = action.basePrice * quantity;
  const planDiscount = getPlanDiscount(plan);
  const bulkDiscount = getBulkDiscount(quantity);

  return baseTotal * (1 - Math.max(planDiscount, bulkDiscount));
};