import React from 'react';
import { Container, Button } from './shared';
import { BarChart, Shield, Zap, Users, PieChart, Clock } from 'lucide-react';

const benefits = [
  {
    icon: Users,
    title: 'Access Global Talent',
    description: 'Tap into our worldwide community of skilled workers ready to complete your tasks efficiently.'
  },
  {
    icon: Zap,
    title: 'Quick Turnaround',
    description: 'Get tasks completed rapidly with our active user base and automated task distribution system.'
  },
  {
    icon: Shield,
    title: 'Quality Assured',
    description: 'Our multi-tier review system and skilled workforce ensure high-quality task completion.'
  },
  {
    icon: PieChart,
    title: 'Cost Effective',
    description: 'Pay only for completed tasks with transparent pricing and no hidden fees.'
  },
  {
    icon: BarChart,
    title: 'Detailed Analytics',
    description: 'Track task performance, completion rates, and quality metrics in real-time.'
  },
  {
    icon: Clock,
    title: 'Time Saving',
    description: 'Automate repetitive tasks and scale your operations efficiently.'
  }
];

export const ForBusiness = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white" id="for-business">
      <Container>
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Power Your Business with ToCash
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Post tasks, get quality results, and scale your operations with our global workforce
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {benefits.map((benefit, index) => {
            const Icon = benefit.icon;
            return (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group"
              >
                <div className="bg-indigo-50 w-12 h-12 rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            );
          })}
        </div>

        <div className="bg-indigo-600 rounded-2xl p-8 md:p-12 text-center text-white">
          <h3 className="text-2xl md:text-3xl font-bold mb-4">
            Ready to Scale Your Operations?
          </h3>
          <p className="text-indigo-100 mb-8 max-w-2xl mx-auto">
            Join leading businesses that trust ToCash for their task completion needs.
            Get started today and experience the power of our global workforce.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button
              variant="secondary"
              size="lg"
              className="bg-white text-indigo-600 hover:bg-indigo-50"
            >
              Schedule a Demo
            </Button>
            <Button
              variant="outline"
              size="lg"
              className="border-white text-white hover:bg-white/10"
            >
              View Pricing
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};