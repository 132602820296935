import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { IndividualOverview } from './IndividualOverview';
import { IndividualTasks } from './IndividualTasks';
import { Earnings } from './Earnings';
import { TaskWorkspace } from '../TaskWorkspace';

export const IndividualDashboard = () => {
  return (
    <Routes>
      <Route index element={<IndividualOverview />} />
      <Route path="tasks" element={<IndividualTasks />} />
      <Route path="tasks/:taskId/workspace" element={<TaskWorkspace />} />
      <Route path="earnings" element={<Earnings />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};