import React, { useState } from 'react';
import { Button } from '../shared';
import { 
  SocialMediaPlatform, 
  SocialMediaActionType 
} from '../../types/task';
import { taskPricingService } from '../../services/TaskPricingService';
import { 
  Facebook, 
  Instagram, 
  Twitter, 
  Youtube, 
  Linkedin,
  ThumbsUp,
  MessageCircle,
  Share2,
  UserPlus,
  ExternalLink
} from 'lucide-react';

interface SocialMediaTaskFormProps {
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const platforms: { id: SocialMediaPlatform; name: string; icon: any }[] = [
  { id: 'facebook', name: 'Facebook', icon: Facebook },
  { id: 'instagram', name: 'Instagram', icon: Instagram },
  { id: 'twitter', name: 'Twitter', icon: Twitter },
  { id: 'youtube', name: 'YouTube', icon: Youtube },
  { id: 'linkedin', name: 'LinkedIn', icon: Linkedin },
];

const actions: { id: SocialMediaActionType; name: string; icon: any }[] = [
  { id: 'follow', name: 'Follow', icon: UserPlus },
  { id: 'like', name: 'Like', icon: ThumbsUp },
  { id: 'comment', name: 'Comment', icon: MessageCircle },
  { id: 'share', name: 'Share', icon: Share2 },
  { id: 'click_through', name: 'Click Through', icon: ExternalLink },
];

export const SocialMediaTaskForm: React.FC<SocialMediaTaskFormProps> = ({
  onSubmit,
  onCancel
}) => {
  const [formData, setFormData] = useState({
    platform: '' as SocialMediaPlatform,
    actionType: '' as SocialMediaActionType,
    targetUrl: '',
    requiredActions: 100,
    commentTemplate: '',
    plan: 'starter' as keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans,
    currency: 'USD' as 'USD' | 'NGN'
  });

  const [budget, setBudget] = useState({
    total: 0,
    costPerAction: 0
  });

  const updateBudget = () => {
    if (formData.actionType && formData.requiredActions) {
      const calculatedBudget = taskPricingService.calculateTaskBudget(
        formData.actionType,
        formData.requiredActions,
        formData.plan,
        formData.currency
      );
      setBudget({
        total: calculatedBudget.total,
        costPerAction: calculatedBudget.costPerAction
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      budget: {
        total: budget.total,
        remaining: budget.total,
        costPerAction: budget.costPerAction,
        currency: formData.currency
      }
    });
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (['actionType', 'requiredActions', 'plan', 'currency'].includes(field)) {
      setTimeout(updateBudget, 0);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Platform Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Social Media Platform
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          {platforms.map(({ id, name, icon: Icon }) => (
            <button
              key={id}
              type="button"
              onClick={() => handleChange('platform', id)}
              className={`flex items-center gap-2 p-3 rounded-lg border ${
                formData.platform === id
                  ? 'border-indigo-600 bg-indigo-50 text-indigo-600'
                  : 'border-gray-300 hover:border-indigo-600'
              }`}
            >
              <Icon className="h-5 w-5" />
              <span>{name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Action Type Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Action Type
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          {actions.map(({ id, name, icon: Icon }) => (
            <button
              key={id}
              type="button"
              onClick={() => handleChange('actionType', id)}
              className={`flex items-center gap-2 p-3 rounded-lg border ${
                formData.actionType === id
                  ? 'border-indigo-600 bg-indigo-50 text-indigo-600'
                  : 'border-gray-300 hover:border-indigo-600'
              }`}
            >
              <Icon className="h-5 w-5" />
              <span>{name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Target URL */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Target URL
        </label>
        <input
          type="url"
          value={formData.targetUrl}
          onChange={(e) => handleChange('targetUrl', e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="https://..."
          required
        />
      </div>

      {/* Required Actions */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Number of Required Actions
        </label>
        <input
          type="number"
          min="1"
          value={formData.requiredActions}
          onChange={(e) => handleChange('requiredActions', parseInt(e.target.value))}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      {/* Comment Template (if action type is comment) */}
      {formData.actionType === 'comment' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Comment Template
          </label>
          <textarea
            value={formData.commentTemplate}
            onChange={(e) => handleChange('commentTemplate', e.target.value)}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter template for comments..."
            required
          />
        </div>
      )}

      {/* Currency Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Currency
        </label>
        <select
          value={formData.currency}
          onChange={(e) => handleChange('currency', e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="USD">USD</option>
          <option value="NGN">NGN</option>
        </select>
      </div>

      {/* Budget Preview */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Budget Preview</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500">Cost per Action</p>
            <p className="text-lg font-semibold">
              {formData.currency === 'USD' ? '$' : '₦'}
              {budget.costPerAction.toFixed(2)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Total Budget</p>
            <p className="text-lg font-semibold">
              {formData.currency === 'USD' ? '$' : '₦'}
              {budget.total.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end gap-3">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          Create Task
        </Button>
      </div>
    </form>
  );
};