import React, { useEffect, useState, useRef } from 'react';
import { Container } from './shared';
import { DollarSign, Users, Target, Award } from 'lucide-react';

const stats = [
  {
    icon: DollarSign,
    value: 5000000,
    label: 'Total Earnings Paid',
    prefix: '$',
    format: true
  },
  {
    icon: Users,
    value: 50000,
    label: 'Active Users',
    format: true
  },
  {
    icon: Target,
    value: 1000000,
    label: 'Tasks Completed',
    format: true
  },
  {
    icon: Award,
    value: 98,
    label: 'User Satisfaction',
    suffix: '%'
  }
];

const formatNumber = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

const AnimatedCounter = ({ 
  value, 
  prefix = '', 
  suffix = '', 
  format = false 
}: { 
  value: number;
  prefix?: string;
  suffix?: string;
  format?: boolean;
}) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(0);
  const frameRef = useRef(0);

  useEffect(() => {
    const duration = 2000;
    const frames = 60;
    const increment = value / frames;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      countRef.current = Math.min(
        Math.ceil(progress * value),
        value
      );
      setCount(countRef.current);

      if (progress < 1) {
        frameRef.current = requestAnimationFrame(animate);
      }
    };

    frameRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(frameRef.current);
    };
  }, [value]);

  return (
    <span className="text-4xl font-bold text-indigo-600">
      {prefix}
      {format ? formatNumber(count) : count}
      {suffix}
    </span>
  );
};

export const Statistics = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={sectionRef}
      className="py-20 bg-white"
    >
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Impact in Numbers</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Join our growing community and be part of our success story
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div
                key={index}
                className="bg-gradient-to-br from-white to-indigo-50 p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group"
              >
                <div className="flex items-center justify-center mb-4">
                  <div className="bg-indigo-100 w-12 h-12 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform">
                    <Icon className="h-6 w-6 text-indigo-600" />
                  </div>
                </div>
                <div className="text-center">
                  {isVisible && (
                    <AnimatedCounter 
                      value={stat.value}
                      prefix={stat.prefix}
                      suffix={stat.suffix}
                      format={stat.format}
                    />
                  )}
                  <p className="text-gray-600 mt-2">{stat.label}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};