import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  BarChart2, 
  Users, 
  Clock, 
  ExternalLink,
  CheckCircle,
  AlertCircle,
  DollarSign,
  List
} from 'lucide-react';
import { Task, TaskSubmission } from '../../../types/task';
import { taskService } from '../../../services/TaskService';
import { useNotifications } from '../../../context/NotificationContext';
import { Button } from '../../../components/shared';
import { ReviewSubmissionModal } from '../../../components/tasks/ReviewSubmissionModal';
import { TaskEditWindow } from '../../../components/tasks/TaskEditWindow';
import { CreateTaskModal } from '../../../components/tasks/CreateTaskModal';

export const TaskTracker = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState<Task | null>(null);
  const [submissions, setSubmissions] = useState<TaskSubmission[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<TaskSubmission | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { addNotification } = useNotifications();

  useEffect(() => {
    loadTask();
  }, [taskId]);

  const loadTask = async () => {
    if (!taskId) return;

    try {
      const [taskData, submissionData] = await Promise.all([
        taskService.getTaskById(taskId),
        taskService.getTaskSubmissions(taskId)
      ]);

      if (taskData) {
        setTask(taskData);
        setSubmissions(submissionData);
      } else {
        addNotification({
          type: 'error',
          title: 'Error',
          message: 'Task not found'
        });
        navigate('/dashboard/tasks');
      }
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to load task'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditComplete = async (updatedTask: Task) => {
    try {
      await taskService.editTask(updatedTask.id, updatedTask);
      setTask(updatedTask);
      setShowEditModal(false);
      addNotification({
        type: 'success',
        title: 'Task Updated',
        message: 'Task details have been updated successfully'
      });
    } catch (error: any) {
      addNotification({
        type: 'error',
        title: 'Update Failed',
        message: error.message || 'Failed to update task'
      });
    }
  };

  // Rest of the component remains the same, just add TaskEditWindow and CreateTaskModal

  if (isLoading || !task) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <TaskEditWindow task={task} onEdit={() => setShowEditModal(true)} />
      
      {/* Rest of the JSX remains the same */}

      {showEditModal && (
        <CreateTaskModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          onSubmit={handleEditComplete}
          initialData={task}
          isEditing={true}
        />
      )}
    </div>
  );
};