import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TaskCard } from './TaskCard';
import { Task } from '../../types/task';

interface VirtualizedTaskListProps {
  tasks: Task[];
  onAcceptTask?: (taskId: string) => void;
}

export const VirtualizedTaskList: React.FC<VirtualizedTaskListProps> = ({
  tasks,
  onAcceptTask
}) => {
  const renderRow = React.useCallback(({ index, style }: { index: number; style: React.CSSProperties }) => (
    <div style={style}>
      <div className="px-3">
        <TaskCard
          task={tasks[index]}
          onAccept={onAcceptTask}
        />
      </div>
    </div>
  ), [tasks, onAcceptTask]);

  return (
    <div className="h-[calc(100vh-16rem)]">
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemCount={tasks.length}
            itemSize={220}
            width={width}
            className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
          >
            {renderRow}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};