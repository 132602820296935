import React from 'react';
import { Container } from './shared';
import { BookOpen, TrendingUp, Star, ArrowRight } from 'lucide-react';

const articles = [
  {
    category: 'Featured',
    title: 'How to Maximize Your Earnings on ToCash',
    excerpt: 'Learn the proven strategies top earners use to maximize their income on our platform.',
    image: 'https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?auto=format&fit=crop&w=800&q=80',
    readTime: '5 min read',
    date: '2024-03-10'
  },
  {
    category: 'Tips & Tricks',
    title: 'Best Practices for Task Completion',
    excerpt: 'Essential tips to ensure high-quality task completion and maintain a stellar rating.',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&w=800&q=80',
    readTime: '4 min read',
    date: '2024-03-09'
  },
  {
    category: 'Success Stories',
    title: 'From Beginner to Top Earner: Sarah\'s Journey',
    excerpt: 'How one user turned ToCash into a full-time income stream in just six months.',
    image: 'https://images.unsplash.com/photo-1573164713714-d95e436ab8d6?auto=format&fit=crop&w=800&q=80',
    readTime: '6 min read',
    date: '2024-03-08'
  }
];

export const BlogPreview = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-indigo-50">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Latest from Our Blog</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Insights, tips, and success stories to help you succeed
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {articles.map((article, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow group"
            >
              <div className="relative h-48">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-4 left-4">
                  <span className="px-3 py-1 bg-white rounded-full text-sm font-medium text-indigo-600">
                    {article.category}
                  </span>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2 group-hover:text-indigo-600 transition-colors">
                  {article.title}
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {article.excerpt}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{article.readTime}</span>
                  <span>{new Date(article.date).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <a
            href="/blog"
            className="inline-flex items-center text-indigo-600 hover:text-indigo-700 font-medium"
          >
            View all articles
            <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </Container>
    </section>
  );
};