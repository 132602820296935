import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppRoutes } from './AppRoutes';
import { NotificationProvider } from './context/NotificationContext';
import { NotificationPreferencesProvider } from './context/NotificationPreferencesContext';
import { FirebaseProvider } from './context/FirebaseContext';
import { ErrorBoundary } from 'react-error-boundary';
import { NotificationPrompt } from './components/notifications/NotificationPrompt';

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div role="alert" className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-bold text-red-600 mb-4">Something went wrong</h2>
        <pre className="text-sm bg-red-50 p-4 rounded overflow-auto">
          {error.message}
        </pre>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
}

export default function App() {
  const origin = window.location.origin;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${origin}/callback`,
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: import.meta.env.VITE_AUTH0_SCOPE
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <NotificationProvider>
          <NotificationPreferencesProvider>
            <FirebaseProvider>
              <BrowserRouter>
                <AppRoutes />
                <NotificationPrompt />
              </BrowserRouter>
            </FirebaseProvider>
          </NotificationPreferencesProvider>
        </NotificationProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}