import React, { createContext, useContext, useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useNotifications } from './NotificationContext';
import { 
  requestNotificationPermission, 
  isNotificationsSupported, 
  getNotificationPermission 
} from '../services/firebase';
import { logger } from '../utils/logger';

interface NotificationPreferences {
  hasSeenPrompt: boolean;
  preference: NotificationPermission;
  showSettingsGuide: boolean;
  lastAttempt?: number;
  attemptCount: number;
}

interface NotificationPreferencesContextType {
  preferences: NotificationPreferences;
  requestPermission: () => Promise<boolean>;
  skipNotifications: () => void;
  hideSettingsGuide: () => void;
  resetPreferences: () => void;
  canRequestPermission: boolean;
  supportDetails: {
    supported: boolean;
    reasons: string[];
  };
}

const NotificationPreferencesContext = createContext<NotificationPreferencesContextType | undefined>(undefined);

const PREFERENCES_COOKIE = 'notification_preferences';
const MAX_ATTEMPTS = 3;
const ATTEMPT_COOLDOWN = 24 * 60 * 60 * 1000; // 24 hours

export const NotificationPreferencesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [preferences, setPreferences] = useState<NotificationPreferences>(() => {
    const saved = Cookies.get(PREFERENCES_COOKIE);
    return saved ? JSON.parse(saved) : {
      hasSeenPrompt: false,
      preference: 'default',
      showSettingsGuide: false,
      attemptCount: 0
    };
  });

  const { addNotification } = useNotifications();
  const supportDetails = isNotificationsSupported();

  const savePreferences = useCallback((newPreferences: NotificationPreferences) => {
    setPreferences(newPreferences);
    Cookies.set(PREFERENCES_COOKIE, JSON.stringify(newPreferences), { expires: 365 });
  }, []);

  const canRequestPermission = useCallback(() => {
    if (!supportDetails.supported) return false;
    if (preferences.preference === 'granted') return false;
    if (preferences.preference === 'denied' && preferences.attemptCount >= MAX_ATTEMPTS) {
      const lastAttempt = preferences.lastAttempt || 0;
      const timeSinceLastAttempt = Date.now() - lastAttempt;
      return timeSinceLastAttempt >= ATTEMPT_COOLDOWN;
    }
    return true;
  }, [preferences, supportDetails.supported]);

  const requestPermission = useCallback(async () => {
    if (!supportDetails.supported) {
      addNotification({
        type: 'error',
        title: 'Notifications Not Supported',
        message: supportDetails.reasons.join('. ')
      });
      return false;
    }

    if (!canRequestPermission()) {
      if (preferences.attemptCount >= MAX_ATTEMPTS) {
        addNotification({
          type: 'warning',
          title: 'Too Many Attempts',
          message: 'Please try again in 24 hours'
        });
      }
      return false;
    }

    try {
      const granted = await requestNotificationPermission();
      const newPreferences = {
        ...preferences,
        hasSeenPrompt: true,
        preference: granted ? 'granted' : 'denied',
        showSettingsGuide: !granted,
        lastAttempt: Date.now(),
        attemptCount: preferences.attemptCount + 1
      };
      
      savePreferences(newPreferences);

      if (!granted && newPreferences.attemptCount < MAX_ATTEMPTS) {
        addNotification({
          type: 'info',
          title: 'Notifications Blocked',
          message: 'You can enable notifications in your browser settings to receive updates.'
        });
      }

      return granted;
    } catch (error) {
      logger.error('Failed to request notification permission:', error);
      addNotification({
        type: 'error',
        title: 'Permission Request Failed',
        message: 'Please try again later'
      });
      return false;
    }
  }, [preferences, supportDetails, canRequestPermission, addNotification, savePreferences]);

  const skipNotifications = useCallback(() => {
    savePreferences({
      ...preferences,
      hasSeenPrompt: true,
      preference: 'denied',
      showSettingsGuide: false
    });
  }, [preferences, savePreferences]);

  const hideSettingsGuide = useCallback(() => {
    savePreferences({
      ...preferences,
      showSettingsGuide: false
    });
  }, [preferences, savePreferences]);

  const resetPreferences = useCallback(() => {
    savePreferences({
      hasSeenPrompt: false,
      preference: 'default',
      showSettingsGuide: false,
      attemptCount: 0
    });
  }, [savePreferences]);

  return (
    <NotificationPreferencesContext.Provider
      value={{
        preferences,
        requestPermission,
        skipNotifications,
        hideSettingsGuide,
        resetPreferences,
        canRequestPermission: canRequestPermission(),
        supportDetails
      }}
    >
      {children}
    </NotificationPreferencesContext.Provider>
  );
};

export const useNotificationPreferences = () => {
  const context = useContext(NotificationPreferencesContext);
  if (context === undefined) {
    throw new Error('useNotificationPreferences must be used within a NotificationPreferencesProvider');
  }
  return context;
};