import React, { useState } from 'react';
import { Search, Filter } from 'lucide-react';
import { Button } from '../../../components/shared';
import { WorkerCard } from '../../../components/workers/WorkerCard';
import { WorkerDetailsModal } from '../../../components/workers/WorkerDetailsModal';

const mockWorkers = [
  {
    id: '1',
    name: 'Sarah Chen',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=128&h=128&q=80',
    level: 5,
    rating: 4.9,
    tasksCompleted: 156,
    expertise: ['Content Writing', 'Research', 'Data Entry'],
    joinedDate: '2024-01-15',
    status: 'active',
    completionRate: 98,
    avgResponseTime: '2 hours'
  },
  {
    id: '2',
    name: 'Michael Johnson',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=128&h=128&q=80',
    level: 4,
    rating: 4.7,
    tasksCompleted: 89,
    expertise: ['Testing', 'Virtual Assistance'],
    joinedDate: '2024-02-01',
    status: 'active',
    completionRate: 95,
    avgResponseTime: '1.5 hours'
  }
];

export const BusinessWorkers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExpertise, setSelectedExpertise] = useState<string>('all');
  const [selectedWorker, setSelectedWorker] = useState<any>(null);

  const filteredWorkers = mockWorkers.filter(worker => {
    const matchesSearch = worker.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesExpertise = selectedExpertise === 'all' || 
      worker.expertise.some(exp => exp.toLowerCase() === selectedExpertise.toLowerCase());
    return matchesSearch && matchesExpertise;
  });

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Worker Management</h1>
        <p className="mt-1 text-gray-600">
          Monitor and manage your task workers
        </p>
      </div>

      <div className="flex flex-wrap gap-4">
        <div className="flex-1 min-w-[200px]">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        <div className="flex gap-2">
          <select
            value={selectedExpertise}
            onChange={(e) => setSelectedExpertise(e.target.value)}
            className="border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Expertise</option>
            <option value="content writing">Content Writing</option>
            <option value="research">Research</option>
            <option value="data entry">Data Entry</option>
            <option value="testing">Testing</option>
            <option value="virtual assistance">Virtual Assistance</option>
          </select>

          <Button variant="secondary" className="flex items-center gap-2">
            <Filter className="h-5 w-5" />
            More Filters
          </Button>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredWorkers.map((worker) => (
          <WorkerCard
            key={worker.id}
            worker={worker}
            onClick={() => setSelectedWorker(worker)}
          />
        ))}
      </div>

      {filteredWorkers.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500">No workers found matching your criteria.</p>
        </div>
      )}

      <WorkerDetailsModal
        worker={selectedWorker}
        onClose={() => setSelectedWorker(null)}
      />
    </div>
  );
};