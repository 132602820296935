import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TaskList } from '../../components/tasks/TaskList';
import { LoadingScreen } from '../../components/LoadingScreen';

export const Tasks = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Tasks</h1>
        <p className="mt-1 text-gray-600">
          {user?.['https://tocash.io/user_type'] === 'business' 
            ? 'Manage and track your tasks'
            : 'Browse available tasks and track your progress'
          }
        </p>
      </div>

      <TaskList />
    </div>
  );
};