import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Button } from './shared';
import { PaymentMethod } from '../types/payment';
import { paymentConfigs } from '../services/PaymentConfigService';
import { usePayment } from '../hooks/usePayment';
import { useAuth0 } from '@auth0/auth0-react';

interface WithdrawalModalProps {
  isOpen: boolean;
  onClose: () => void;
  availableBalance: number;
  onSuccess?: (amount: number, method: string) => void;
}

export const WithdrawalModal: React.FC<WithdrawalModalProps> = ({
  isOpen,
  onClose,
  availableBalance,
  onSuccess
}) => {
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [accountDetails, setAccountDetails] = useState<Record<string, string>>({});
  const { initiateWithdrawal, loading } = usePayment();
  const { user } = useAuth0();

  if (!isOpen) return null;

  const selectedConfig = selectedMethod ? paymentConfigs[selectedMethod] : null;

  const handleSubmit = async () => {
    if (!selectedMethod || !amount || !user?.sub) return;

    try {
      const numAmount = parseFloat(amount);
      const response = await initiateWithdrawal({
        userId: user.sub,
        method: selectedMethod,
        amount: numAmount,
        currency: selectedConfig?.currency || 'USD',
        accountDetails
      });

      if (response?.success) {
        onSuccess?.(numAmount, selectedMethod);
        onClose();
      }
    } catch (error) {
      console.error('Withdrawal failed:', error);
    }
  };

  const calculateFee = (amount: number): number => {
    if (!selectedConfig) return 0;
    return (amount * selectedConfig.processingFee.percentage / 100) + selectedConfig.processingFee.fixed;
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-md w-full">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold">Withdraw Earnings</h3>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-6">
            <div>
              <p className="text-sm text-gray-500">Available Balance</p>
              <p className="text-2xl font-bold text-gray-900">
                ${availableBalance.toFixed(2)}
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Payment Method
              </label>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(paymentConfigs).map(([id, config]) => (
                  <button
                    key={id}
                    onClick={() => setSelectedMethod(id as PaymentMethod)}
                    className={`p-4 rounded-lg border flex items-center gap-2 transition-colors ${
                      selectedMethod === id
                        ? 'border-indigo-600 bg-indigo-50 text-indigo-600'
                        : 'border-gray-200 hover:border-indigo-600'
                    }`}
                  >
                    <span className="text-sm">{config.name}</span>
                  </button>
                ))}
              </div>
            </div>

            {selectedConfig && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Amount ({selectedConfig.currency})
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">
                        {selectedConfig.currency === 'NGN' ? '₦' : '$'}
                      </span>
                    </div>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="block w-full pl-7 pr-12 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="0.00"
                      min={selectedConfig.minAmount}
                      max={Math.min(selectedConfig.maxAmount, availableBalance)}
                    />
                  </div>
                  <div className="mt-1 text-sm text-gray-500 flex justify-between">
                    <span>Min: {selectedConfig.currency === 'NGN' ? '₦' : '$'}{selectedConfig.minAmount}</span>
                    <span>Max: {selectedConfig.currency === 'NGN' ? '₦' : '$'}{selectedConfig.maxAmount}</span>
                  </div>
                </div>

                {selectedConfig.fields.map((field) => (
                  <div key={field}>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {field.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </label>
                    <input
                      type="text"
                      value={accountDetails[field] || ''}
                      onChange={(e) => setAccountDetails(prev => ({
                        ...prev,
                        [field]: e.target.value
                      }))}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder={`Enter ${field.split('_').join(' ')}`}
                    />
                  </div>
                ))}

                {amount && (
                  <div className="p-4 bg-gray-50 rounded-lg space-y-2">
                    <div className="flex justify-between text-gray-600">
                      <span>Amount:</span>
                      <span>{selectedConfig.currency === 'NGN' ? '₦' : '$'}{parseFloat(amount).toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between text-gray-600">
                      <span>Fee:</span>
                      <span>{selectedConfig.currency === 'NGN' ? '₦' : '$'}{calculateFee(parseFloat(amount)).toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between font-medium text-gray-900 pt-2 border-t">
                      <span>You'll receive:</span>
                      <span>
                        {selectedConfig.currency === 'NGN' ? '₦' : '$'}
                        {(parseFloat(amount) - calculateFee(parseFloat(amount))).toFixed(2)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="flex gap-3">
              <Button
                variant="primary"
                className="flex-1"
                onClick={handleSubmit}
                disabled={loading || !selectedMethod || !amount || parseFloat(amount) > availableBalance}
              >
                {loading ? 'Processing...' : 'Withdraw'}
              </Button>
              <Button variant="secondary" className="flex-1" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};