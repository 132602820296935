import React, { useState } from 'react';
import { Search, Filter, X } from 'lucide-react';
import { Button } from '../shared';
import { TaskCategory, TaskDifficulty } from '../../types/task';

type FilterValues = {
  searchTerm: string;
  category: TaskCategory | '';
  difficulty: TaskDifficulty | '';
  minReward: number;
  maxReward: number;
  status: string[];
};

type TaskFiltersProps = {
  onFilterChange: (filters: FilterValues) => void;
  onReset: () => void;
};

export const TaskFilters: React.FC<TaskFiltersProps> = ({
  onFilterChange,
  onReset
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState<TaskCategory | ''>('');
  const [difficulty, setDifficulty] = useState<TaskDifficulty | ''>('');
  const [minReward, setMinReward] = useState<number>(0);
  const [maxReward, setMaxReward] = useState<number>(0);
  const [status, setStatus] = useState<string[]>([]);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleFilterChange = () => {
    onFilterChange({
      searchTerm,
      category,
      difficulty,
      minReward,
      maxReward,
      status
    });
  };

  const handleReset = () => {
    setSearchTerm('');
    setCategory('');
    setDifficulty('');
    setMinReward(0);
    setMaxReward(0);
    setStatus([]);
    onReset();
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-4">
        {/* Search Input */}
        <div className="flex-1 min-w-[200px]">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleFilterChange();
              }}
              placeholder="Search tasks..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        {/* Basic Filters */}
        <div className="flex gap-2">
          <select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value as TaskCategory);
              handleFilterChange();
            }}
            className="border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">All Categories</option>
            <option value="content_writing">Content Writing</option>
            <option value="data_entry">Data Entry</option>
            <option value="research">Research</option>
            <option value="testing">Testing</option>
            <option value="transcription">Transcription</option>
            <option value="translation">Translation</option>
            <option value="virtual_assistance">Virtual Assistance</option>
            <option value="social_media">Social Media</option>
            <option value="other">Other</option>
          </select>

          <select
            value={difficulty}
            onChange={(e) => {
              setDifficulty(e.target.value as TaskDifficulty);
              handleFilterChange();
            }}
            className="border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">All Difficulties</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="expert">Expert</option>
          </select>

          <Button
            variant="secondary"
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="flex items-center gap-2"
          >
            {showAdvanced ? (
              <X className="h-5 w-5" />
            ) : (
              <Filter className="h-5 w-5" />
            )}
            {showAdvanced ? 'Hide Filters' : 'More Filters'}
          </Button>

          {(searchTerm || category || difficulty || status.length > 0) && (
            <Button
              variant="secondary"
              onClick={handleReset}
            >
              Reset
            </Button>
          )}
        </div>
      </div>

      {/* Advanced Filters */}
      {showAdvanced && (
        <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Minimum Reward
              </label>
              <input
                type="number"
                value={minReward}
                onChange={(e) => {
                  setMinReward(Number(e.target.value));
                  handleFilterChange();
                }}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                min="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Maximum Reward
              </label>
              <input
                type="number"
                value={maxReward}
                onChange={(e) => {
                  setMaxReward(Number(e.target.value));
                  handleFilterChange();
                }}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                min="0"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Status
            </label>
            <div className="flex flex-wrap gap-2">
              {['open', 'in_progress', 'completed'].map((s) => (
                <label key={s} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={status.includes(s)}
                    onChange={(e) => {
                      const newStatus = e.target.checked
                        ? [...status, s]
                        : status.filter(st => st !== s);
                      setStatus(newStatus);
                      handleFilterChange();
                    }}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="ml-2 text-sm text-gray-700 capitalize">
                    {s.replace('_', ' ')}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};