import React, { useEffect } from 'react';
import { Bell, Settings, X, AlertCircle } from 'lucide-react';
import { Button } from '../shared';
import { useNotificationPreferences } from '../../context/NotificationPreferencesContext';

export const NotificationPrompt = () => {
  const { 
    preferences, 
    requestPermission, 
    skipNotifications, 
    hideSettingsGuide,
    canRequestPermission,
    supportDetails
  } = useNotificationPreferences();

  // Auto-dismiss after 10 seconds if not interacted with
  useEffect(() => {
    if (!preferences.hasSeenPrompt && !preferences.showSettingsGuide) {
      const timer = setTimeout(() => {
        skipNotifications();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [preferences.hasSeenPrompt, preferences.showSettingsGuide, skipNotifications]);

  if (!supportDetails.supported || preferences.hasSeenPrompt && !preferences.showSettingsGuide) {
    return null;
  }

  if (preferences.showSettingsGuide) {
    return (
      <div className="fixed bottom-4 right-4 max-w-sm bg-white rounded-lg shadow-lg p-4 border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="bg-yellow-100 rounded-lg p-2">
            <Settings className="h-6 w-6 text-yellow-600" />
          </div>
          <div className="flex-1">
            <h3 className="text-sm font-medium text-gray-900 mb-1">
              Enable Notifications
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              1. Click the lock icon in your browser's address bar<br />
              2. Find "Notifications" in the site settings<br />
              3. Change the setting to "Allow"
            </p>
            <div className="flex justify-end">
              <Button
                variant="secondary"
                size="sm"
                onClick={hideSettingsGuide}
              >
                Got It
              </Button>
            </div>
          </div>
          <button
            onClick={hideSettingsGuide}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 max-w-sm bg-white rounded-lg shadow-lg p-4 border border-gray-200">
      <div className="flex items-start gap-4">
        <div className="bg-indigo-100 rounded-lg p-2">
          <Bell className="h-6 w-6 text-indigo-600" />
        </div>
        <div className="flex-1">
          <h3 className="text-sm font-medium text-gray-900 mb-1">
            Stay Updated
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            Enable notifications to receive updates about your tasks, earnings, and important announcements.
          </p>
          <div className="flex justify-end gap-2">
            <Button
              variant="secondary"
              size="sm"
              onClick={skipNotifications}
            >
              Maybe Later
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={requestPermission}
            >
              Enable Notifications
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};