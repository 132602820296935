import { SocialMediaActionType, TaskBudget } from '../types/task';
import { SOCIAL_MEDIA_PRICING, calculateTaskCost } from '../config/pricing';
import { forexService } from './ForexService';

class TaskPricingService {
  private static instance: TaskPricingService;

  private constructor() {}

  public static getInstance(): TaskPricingService {
    if (!TaskPricingService.instance) {
      TaskPricingService.instance = new TaskPricingService();
    }
    return TaskPricingService.instance;
  }

  calculateTaskBudget(
    actionType: SocialMediaActionType,
    quantity: number,
    plan: keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans,
    currency: 'USD' | 'NGN' = 'USD'
  ): TaskBudget {
    const costPerAction = SOCIAL_MEDIA_PRICING.actions[actionType].basePrice;
    const total = calculateTaskCost(actionType, quantity, plan);
    
    if (currency === 'NGN') {
      return {
        total: forexService.convertUSDtoNGN(total),
        remaining: forexService.convertUSDtoNGN(total),
        costPerAction: forexService.convertUSDtoNGN(costPerAction),
        currency: 'NGN'
      };
    }

    return {
      total,
      remaining: total,
      costPerAction,
      currency: 'USD'
    };
  }

  getRequiredTokens(
    actionType: SocialMediaActionType,
    quantity: number
  ): number {
    const tokensPerAction = SOCIAL_MEDIA_PRICING.actions[actionType].tokens;
    return tokensPerAction * quantity;
  }

  getPlanDetails(plan: keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans) {
    return SOCIAL_MEDIA_PRICING.subscriptionPlans[plan];
  }

  calculateTopUpCost(
    plan: keyof typeof SOCIAL_MEDIA_PRICING.subscriptionPlans,
    tokens: number
  ): number {
    const planDetails = this.getPlanDetails(plan);
    return tokens * planDetails.extraTokenPrice;
  }
}

export const taskPricingService = TaskPricingService.getInstance();