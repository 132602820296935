import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { logger } from '../utils/logger';

// Initialize Firebase only once
let app;
let db;
let storage;
let messaging;

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const initializeFirebase = async () => {
  try {
    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    // Initialize Firestore with persistence
    db = getFirestore(app);
    await enableIndexedDbPersistence(db).catch((err) => {
      if (err.code === 'failed-precondition') {
        logger.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
      } else if (err.code === 'unimplemented') {
        logger.warn('The current browser doesn\'t support persistence.');
      }
    });

    // Initialize Storage
    storage = getStorage(app);

    return true;
  } catch (error) {
    logger.error('Failed to initialize Firebase:', error);
    return false;
  }
};

// Register service worker for FCM with retries
const registerServiceWorker = async (retryCount = 0): Promise<ServiceWorkerRegistration | null> => {
  try {
    if (!('serviceWorker' in navigator)) {
      logger.warn('Service workers are not supported in this browser');
      return null;
    }

    // Pass Firebase config to service worker
    const config = { ...firebaseConfig };
    const blob = new Blob(
      [`self.FIREBASE_CONFIG = ${JSON.stringify(config)}`],
      { type: 'text/javascript' }
    );
    const configUrl = URL.createObjectURL(blob);

    // Import config before service worker
    await navigator.serviceWorker.register(configUrl);
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    logger.info('Service Worker registered successfully');
    return registration;
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      logger.warn(`Service Worker registration failed, retrying (${retryCount + 1}/${MAX_RETRIES})...`);
      await delay(RETRY_DELAY * Math.pow(2, retryCount));
      return registerServiceWorker(retryCount + 1);
    }
    
    logger.error('Service Worker registration failed after retries:', error);
    return null;
  }
};

// Initialize FCM with retries
const initializeFCM = async (retryCount = 0): Promise<string | null> => {
  try {
    if (!await isSupported()) {
      logger.info('Firebase Cloud Messaging is not supported in this environment');
      return null;
    }

    if (!('Notification' in window)) {
      logger.info('This browser does not support notifications');
      return null;
    }

    if (Notification.permission === 'denied') {
      logger.info('User has denied notification permission');
      return null;
    }

    // Register service worker first
    const registration = await registerServiceWorker();
    if (!registration) {
      throw new Error('Failed to register service worker');
    }

    messaging = getMessaging(app);
    
    if (Notification.permission === 'granted') {
      try {
        const token = await getToken(messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration
        });
        logger.info('FCM Token obtained successfully');
        return token;
      } catch (error) {
        throw new Error(`Failed to get FCM token: ${error.message}`);
      }
    }

    return null;
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      logger.warn(`FCM initialization failed, retrying (${retryCount + 1}/${MAX_RETRIES})...`);
      await delay(RETRY_DELAY * Math.pow(2, retryCount));
      return initializeFCM(retryCount + 1);
    }
    
    logger.error('FCM initialization failed after retries:', error);
    return null;
  }
};

// Check if notifications are supported with detailed diagnostics
const isNotificationsSupported = (): { 
  supported: boolean; 
  reasons: string[] 
} => {
  const reasons: string[] = [];

  if (!('Notification' in window)) {
    reasons.push('Browser does not support notifications');
  }

  if (!('serviceWorker' in navigator)) {
    reasons.push('Service workers are not supported');
  }

  if (!isSupported) {
    reasons.push('Firebase Cloud Messaging is not supported');
  }

  if (!import.meta.env.VITE_FIREBASE_VAPID_KEY) {
    reasons.push('VAPID key is not configured');
  }

  return {
    supported: reasons.length === 0,
    reasons
  };
};

// Get current notification permission with context
const getNotificationPermission = (): {
  permission: NotificationPermission;
  canRequest: boolean;
} => {
  if (!('Notification' in window)) {
    return { permission: 'denied', canRequest: false };
  }

  return {
    permission: Notification.permission,
    canRequest: Notification.permission === 'default'
  };
};

// Request notification permission with error handling
const requestNotificationPermission = async (): Promise<boolean> => {
  try {
    if (!('Notification' in window)) {
      logger.warn('Notifications not supported in this browser');
      return false;
    }

    if (Notification.permission === 'denied') {
      logger.info('Notification permission previously denied');
      return false;
    }

    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Initialize Firebase if not already initialized
      if (!app) {
        const initialized = await initializeFirebase();
        if (!initialized) {
          throw new Error('Failed to initialize Firebase');
        }
      }

      const token = await initializeFCM();
      return Boolean(token);
    }
    
    return false;
  } catch (error) {
    logger.error('Failed to request notification permission:', error);
    return false;
  }
};

// Initialize Firebase on module load
initializeFirebase().catch(error => {
  logger.error('Failed to initialize Firebase on load:', error);
});

export { 
  app, 
  db, 
  storage, 
  messaging, 
  initializeFCM, 
  isNotificationsSupported, 
  getNotificationPermission,
  requestNotificationPermission 
};