import React from 'react';
import { Container, Button } from './shared';
import { Check, Star, Shield, Zap, X } from 'lucide-react';

const plans = [
  {
    name: 'Pro',
    description: 'Perfect for small businesses and startups',
    price: '$25',
    period: '/month',
    yearlyPrice: '$240/year',
    features: [
      'Up to 50 task posts per month',
      'Basic task targeting options',
      'Standard task placement',
      'Basic analytics dashboard',
      'Email support (24h response)',
      'Basic API access',
      'Community forum access',
      'Standard task templates',
      'Monthly performance reports'
    ],
    icon: Zap,
    popular: false
  },
  {
    name: 'Elite',
    description: 'Best for growing businesses',
    price: '$50',
    period: '/month',
    yearlyPrice: '$480/year',
    features: [
      'Unlimited task posts',
      'Advanced targeting algorithms',
      'Priority task placement',
      'Advanced analytics suite',
      'Priority support (4h response)',
      'Advanced API integration',
      'Dedicated account manager',
      'Custom task templates',
      'A/B testing capabilities',
      'Weekly performance insights',
      'Competitor analysis tools',
      'Task automation features'
    ],
    icon: Star,
    popular: true
  },
  {
    name: 'Enterprise',
    description: 'Custom solutions for large organizations',
    price: 'Custom',
    features: [
      'Unlimited task posting',
      'AI-powered targeting',
      'Premium placement guarantee',
      'Custom analytics dashboard',
      '24/7 dedicated support',
      'Enterprise API access',
      'Dedicated success team',
      'White-label solutions',
      'Custom integrations',
      'Real-time reporting',
      'SLA guarantees',
      'Onboarding assistance',
      'Custom workflow automation',
      'Multi-team management'
    ],
    icon: Shield,
    popular: false
  }
];

const specialOffers = [
  {
    title: 'First Campaign Discount',
    description: 'Get 10% off on your first campaign with any paid plan',
    icon: Star
  },
  {
    title: 'Annual Subscription',
    description: 'Save 20% with annual billing on any plan',
    icon: Shield
  },
  {
    title: 'Referral Bonus',
    description: 'Get one month free upgrade for each successful referral',
    icon: Zap
  }
];

const PlanComparison = () => (
  <div className="mt-16 overflow-x-auto">
    <table className="w-full">
      <thead>
        <tr className="border-t border-gray-200">
          <th className="py-5 px-4 text-left text-sm font-medium text-gray-500">Features</th>
          <th className="py-5 px-4 text-center text-sm font-medium text-gray-500">Pro Plan</th>
          <th className="py-5 px-4 text-center text-sm font-medium text-gray-500">Elite Plan</th>
          <th className="py-5 px-4 text-center text-sm font-medium text-gray-500">Enterprise</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {[
          {
            feature: 'Task Posts',
            pro: '50/month',
            elite: 'Unlimited',
            enterprise: 'Unlimited'
          },
          {
            feature: 'Task Targeting',
            pro: 'Basic',
            elite: 'Advanced',
            enterprise: 'AI-Powered'
          },
          {
            feature: 'Analytics',
            pro: 'Basic',
            elite: 'Advanced',
            enterprise: 'Custom'
          },
          {
            feature: 'Support Response',
            pro: '24 hours',
            elite: '4 hours',
            enterprise: '1 hour'
          },
          {
            feature: 'API Access',
            pro: 'Basic',
            elite: 'Advanced',
            enterprise: 'Enterprise'
          },
          {
            feature: 'Account Management',
            pro: 'Community',
            elite: 'Dedicated',
            enterprise: 'Success Team'
          },
          {
            feature: 'Custom Solutions',
            pro: 'Limited',
            elite: 'Available',
            enterprise: 'Full Access'
          },
          {
            feature: 'White Labeling',
            pro: '❌',
            elite: '❌',
            enterprise: '✓'
          }
        ].map((item) => (
          <tr key={item.feature}>
            <td className="py-4 px-4 text-sm text-gray-900">{item.feature}</td>
            <td className="py-4 px-4 text-center text-sm text-gray-600">{item.pro}</td>
            <td className="py-4 px-4 text-center text-sm text-gray-600">{item.elite}</td>
            <td className="py-4 px-4 text-center text-sm text-gray-600">{item.enterprise}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export const Pricing = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-indigo-50" id="pricing">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Pricing Plans for Businesses
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Choose the perfect plan to engage with our global community of taskers
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {plans.map((plan) => {
            const Icon = plan.icon;
            return (
              <div
                key={plan.name}
                className={`relative bg-white rounded-2xl shadow-sm transition-all duration-300 hover:shadow-lg ${
                  plan.popular ? 'ring-2 ring-indigo-600 scale-105 md:-mt-4 md:mb-4' : ''
                }`}
              >
                {plan.popular && (
                  <div className="absolute -top-4 left-0 right-0 mx-auto w-32 rounded-full bg-indigo-600 py-1 px-4 text-sm text-white text-center">
                    Most Popular
                  </div>
                )}

                <div className="p-8">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">{plan.name}</h3>
                      <p className="text-gray-500 mt-1">{plan.description}</p>
                    </div>
                    <div className="bg-indigo-50 p-2 rounded-lg">
                      <Icon className="h-6 w-6 text-indigo-600" />
                    </div>
                  </div>

                  <div className="mb-6">
                    <span className="text-4xl font-bold text-gray-900">{plan.price}</span>
                    {plan.period && (
                      <span className="text-gray-500 font-medium">{plan.period}</span>
                    )}
                    {plan.yearlyPrice && (
                      <p className="text-sm text-indigo-600 mt-1">
                        {plan.yearlyPrice} (Save 20%)
                      </p>
                    )}
                  </div>

                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex items-center gap-3">
                        <Check className="h-5 w-5 text-indigo-600 flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <Button
                    variant={plan.popular ? 'primary' : 'outline'}
                    className="w-full"
                  >
                    {plan.price === 'Custom' ? 'Contact Sales' : 'Get Started'}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Special Offers */}
        <div className="mt-16">
          <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
            Special Offers
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            {specialOffers.map((offer) => {
              const Icon = offer.icon;
              return (
                <div
                  key={offer.title}
                  className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300"
                >
                  <div className="bg-indigo-50 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                    <Icon className="h-6 w-6 text-indigo-600" />
                  </div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-2">
                    {offer.title}
                  </h4>
                  <p className="text-gray-600">{offer.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        <PlanComparison />

        <div className="mt-12 text-center">
          <p className="text-gray-500">
            All plans include our{' '}
            <a href="#" className="text-indigo-600 hover:text-indigo-700 font-medium">
              satisfaction guarantee
            </a>
            . Cancel anytime.
          </p>
        </div>
      </Container>
    </section>
  );
};