import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import { Button } from '../../../components/shared';
import { Calendar, Download } from 'lucide-react';

const taskData = [
  { date: '2024-03-01', completed: 15, amount: 1500 },
  { date: '2024-03-02', completed: 18, amount: 1800 },
  { date: '2024-03-03', completed: 22, amount: 2200 },
  { date: '2024-03-04', completed: 20, amount: 2000 },
  { date: '2024-03-05', completed: 25, amount: 2500 },
  { date: '2024-03-06', completed: 28, amount: 2800 },
  { date: '2024-03-07', completed: 30, amount: 3000 }
];

const categoryData = [
  { name: 'Content Writing', value: 35 },
  { name: 'Data Entry', value: 25 },
  { name: 'Research', value: 20 },
  { name: 'Testing', value: 15 },
  { name: 'Other', value: 5 }
];

const COLORS = ['#4F46E5', '#10B981', '#F59E0B', '#EF4444', '#6B7280'];

export const BusinessAnalytics = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Analytics</h1>
          <p className="mt-1 text-gray-600">
            Monitor your business performance and trends
          </p>
        </div>
        <div className="flex items-center gap-3">
          <Button variant="secondary" className="flex items-center gap-2">
            <Calendar className="h-5 w-5" />
            Last 7 Days
          </Button>
          <Button variant="secondary" className="flex items-center gap-2">
            <Download className="h-5 w-5" />
            Export
          </Button>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-6">
        {/* Task Performance Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Task Performance
          </h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={taskData} margin={{ top: 10, right: 30, left: 0, bottom: 30 }}>
                <defs>
                  <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#4F46E5" stopOpacity={0.1} />
                    <stop offset="95%" stopColor="#4F46E5" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="date"
                  tick={{ fill: '#6B7280' }}
                  tickLine={false}
                  tickFormatter={(value) => new Date(value).toLocaleDateString()}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis
                  yAxisId="left"
                  tick={{ fill: '#6B7280' }}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => `$${value}`}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tick={{ fill: '#6B7280' }}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => `${value} tasks`}
                />
                <Tooltip
                  formatter={(value, name) => {
                    if (name === 'amount') return ['$' + value, 'Revenue'];
                    return [value, 'Tasks Completed'];
                  }}
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="amount"
                  stroke="#4F46E5"
                  fillOpacity={1}
                  fill="url(#colorAmount)"
                  name="amount"
                />
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="completed"
                  stroke="#10B981"
                  fill="none"
                  name="completed"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Task Categories Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Task Categories
          </h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={categoryData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={160}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {categoryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend verticalAlign="bottom" height={36} />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};