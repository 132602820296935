import React from 'react';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className = '' }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 200 50"
      className={className}
    >
      <g transform="translate(10, 5)">
        <circle cx="20" cy="20" r="18" fill="currentColor" className="text-indigo-600"/>
        <path d="M15 12 L25 20 L15 28" stroke="white" strokeWidth="3" fill="none" strokeLinecap="round"/>
        <path d="M25 12 L15 20 L25 28" stroke="white" strokeWidth="3" fill="none" strokeLinecap="round" opacity="0.6"/>
      </g>
      <text x="55" y="32" fontFamily="Arial, sans-serif" fontSize="24" fontWeight="bold" fill="#1F2937">
        To<tspan fill="#4F46E5">Cash</tspan>
      </text>
    </svg>
  );
};

export const LogoIcon: React.FC<LogoProps> = ({ className = '' }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 40 40"
      className={className}
    >
      <circle cx="20" cy="20" r="18" fill="currentColor" className="text-indigo-600"/>
      <path d="M15 12 L25 20 L15 28" stroke="white" strokeWidth="3" fill="none" strokeLinecap="round"/>
      <path d="M25 12 L15 20 L25 28" stroke="white" strokeWidth="3" fill="none" strokeLinecap="round" opacity="0.6"/>
    </svg>
  );
};