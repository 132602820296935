import React, { useState, useEffect } from 'react';
import { Clock, ExternalLink, CheckCircle, XCircle, ListFilter } from 'lucide-react';
import { Button } from '../../../components/shared';
import { taskService } from '../../../services/TaskService';
import { TaskSubmission } from '../../../types/task';
import { ReviewSubmissionModal } from '../../../components/tasks/ReviewSubmissionModal';
import { BatchApprovalModal } from '../../../components/tasks/BatchApprovalModal';
import { useNotifications } from '../../../context/NotificationContext';

export const TaskSubmissions = () => {
  const [submissions, setSubmissions] = useState<TaskSubmission[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<TaskSubmission | null>(null);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubmissions, setSelectedSubmissions] = useState<Set<string>>(new Set());
  const { addNotification } = useNotifications();

  useEffect(() => {
    loadSubmissions();
  }, []);

  const loadSubmissions = async () => {
    try {
      const pendingSubmissions = await taskService.getPendingSubmissions();
      setSubmissions(pendingSubmissions);
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to load submissions'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReview = async (submissionId: string, approved: boolean, feedback?: string) => {
    try {
      await taskService.reviewSubmission(submissionId, approved, feedback);
      
      addNotification({
        type: approved ? 'success' : 'warning',
        title: approved ? 'Submission Approved' : 'Submission Rejected',
        message: approved ? 'Task marked as completed' : 'Task reopened for submissions'
      });

      setSelectedSubmission(null);
      await loadSubmissions();
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to review submission'
      });
    }
  };

  const handleBatchComplete = async () => {
    setShowBatchModal(false);
    setSelectedSubmissions(new Set());
    await loadSubmissions();
  };

  const toggleSubmissionSelection = (submissionId: string) => {
    const newSelection = new Set(selectedSubmissions);
    if (newSelection.has(submissionId)) {
      newSelection.delete(submissionId);
    } else {
      newSelection.add(submissionId);
    }
    setSelectedSubmissions(newSelection);
  };

  const getSelectedSubmissions = () => {
    return submissions.filter(s => selectedSubmissions.has(s.id));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Task Submissions</h1>
          <p className="mt-1 text-gray-600">
            Review and manage task submissions
          </p>
        </div>
        {selectedSubmissions.size > 0 && (
          <Button
            variant="primary"
            onClick={() => setShowBatchModal(true)}
            className="flex items-center gap-2"
          >
            <ListFilter className="h-5 w-5" />
            Process {selectedSubmissions.size} Submissions
          </Button>
        )}
      </div>

      <div className="bg-white rounded-xl shadow-sm divide-y">
        {submissions.length === 0 ? (
          <div className="p-6 text-center text-gray-500">
            No pending submissions to review
          </div>
        ) : (
          submissions.map((submission) => (
            <div 
              key={submission.id}
              className="p-6 hover:bg-gray-50 transition-colors"
            >
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-4">
                  <input
                    type="checkbox"
                    checked={selectedSubmissions.has(submission.id)}
                    onChange={() => toggleSubmissionSelection(submission.id)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      {submission.taskDetails?.title || submission.taskId}
                    </h3>
                    <div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
                      <Clock className="h-4 w-4" />
                      <span>Submitted {new Date(submission.submittedAt).toLocaleString()}</span>
                      {submission.taskDetails?.socialMediaDetails && (
                        <>
                          <span>•</span>
                          <span className="capitalize">
                            {submission.taskDetails.socialMediaDetails.platform}{' '}
                            {submission.taskDetails.socialMediaDetails.actionType.replace('_', ' ')}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setSelectedSubmission(submission)}
                    className="flex items-center gap-1"
                  >
                    <XCircle className="h-4 w-4" />
                    Reject
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setSelectedSubmission(submission)}
                    className="flex items-center gap-1"
                  >
                    <CheckCircle className="h-4 w-4" />
                    Approve
                  </Button>
                </div>
              </div>

              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">
                  Submission Details
                </h4>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-gray-700">{submission.content}</p>
                  {submission.links && submission.links.length > 0 && (
                    <div className="mt-2 space-y-1">
                      {submission.links.map((link, index) => (
                        <a
                          key={index}
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-indigo-700 flex items-center gap-1"
                        >
                          <ExternalLink className="h-4 w-4" />
                          {link}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {selectedSubmission && (
        <ReviewSubmissionModal
          submission={selectedSubmission}
          onClose={() => setSelectedSubmission(null)}
          onReview={handleReview}
        />
      )}

      <BatchApprovalModal
        isOpen={showBatchModal}
        onClose={() => setShowBatchModal(false)}
        submissions={getSelectedSubmissions()}
        onComplete={handleBatchComplete}
      />
    </div>
  );
};