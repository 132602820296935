import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from './shared';
import { Calculator, DollarSign } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';

export const CTA = () => {
  const [hours, setHours] = useState(2);
  const [level, setLevel] = useState(1);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  
  const baseRate = 10;
  const levelMultiplier = 1 + (level - 1) * 0.1;
  const estimatedEarnings = (hours * baseRate * levelMultiplier).toFixed(2);

  const handleStartEarning = async () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      await loginWithRedirect({
        appState: { returnTo: '/onboarding' }
      });
    }
  };

  return (
    <section className="py-20 px-6">
      <Container>
        <div className="max-w-4xl mx-auto bg-indigo-600 rounded-2xl text-white p-8 md:p-12">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-4">Calculate Your Earnings</h2>
              <p className="text-indigo-100 mb-8">
                See how much you could earn based on your time commitment and level.
              </p>
              <Button 
                variant="secondary" 
                size="lg"
                className="w-full md:w-auto group"
                onClick={handleStartEarning}
              >
                Start Earning Now
                <DollarSign className="ml-2 h-5 w-5 group-hover:scale-110 transition-transform" />
              </Button>
            </div>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-indigo-100 mb-2">
                  Hours per day
                </label>
                <input
                  type="range"
                  min="1"
                  max="8"
                  value={hours}
                  onChange={(e) => setHours(parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-sm text-indigo-200">
                  <span>1 hour</span>
                  <span>{hours} hours</span>
                  <span>8 hours</span>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-indigo-100 mb-2">
                  Your Level
                </label>
                <input
                  type="range"
                  min="1"
                  max="10"
                  value={level}
                  onChange={(e) => setLevel(parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-sm text-indigo-200">
                  <span>Level 1</span>
                  <span>Level {level}</span>
                  <span>Level 10</span>
                </div>
              </div>

              <div className="bg-white/10 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <span className="text-indigo-100">Estimated Daily Earnings:</span>
                  <span className="text-2xl font-bold">${estimatedEarnings}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};