import { logger } from '../utils/logger';

// Fallback rates in case API fails
const FALLBACK_RATES = {
  USD_TO_NGN: 1678.67, // Current market rate
  NGN_TO_USD: 1 / 1678.67
};

interface ExchangeRates {
  USD_TO_NGN: number;
  NGN_TO_USD: number;
  lastUpdated: Date;
  source: 'api' | 'fallback';
}

interface ExchangeRateAPIResponse {
  result: string;
  time_last_update_utc: string;
  conversion_rates: {
    NGN: number;
  };
}

class ForexService {
  private static instance: ForexService | null = null;
  private rates: ExchangeRates | null = null;
  private isUpdating = false;
  private retryCount = 0;
  
  private readonly UPDATE_INTERVAL = 60 * 60 * 1000; // 1 hour
  private readonly MAX_RETRIES = 3;
  private readonly RETRY_DELAY = 5000; // 5 seconds
  private readonly FETCH_TIMEOUT = 5000; // 5 seconds
  private readonly RATE_THRESHOLD = 0.2; // 20% change threshold for validation

  private constructor() {
    this.initializeRates();
  }

  public static getInstance(): ForexService {
    if (!ForexService.instance) {
      ForexService.instance = new ForexService();
    }
    return ForexService.instance;
  }

  private async initializeRates(): Promise<void> {
    // Initialize with fallback rates
    this.rates = {
      USD_TO_NGN: FALLBACK_RATES.USD_TO_NGN,
      NGN_TO_USD: FALLBACK_RATES.NGN_TO_USD,
      lastUpdated: new Date(),
      source: 'fallback'
    };

    // Check if API configuration exists
    const apiKey = import.meta.env.VITE_EXCHANGE_RATE_API_KEY;
    const baseUrl = import.meta.env.VITE_EXCHANGE_RATE_API_URL;

    if (!apiKey || !baseUrl) {
      logger.info('Exchange rate API configuration not found, using fallback rates');
      return;
    }

    try {
      // Validate API key with a test request
      const testUrl = `${baseUrl}${apiKey}/latest/USD`;
      const response = await fetch(testUrl);
      
      if (!response.ok) {
        logger.warn('Exchange rate API key validation failed, using fallback rates', {
          status: response.status
        });
        return;
      }

      // If validation succeeds, start periodic updates
      await this.fetchRates();
      setInterval(() => this.fetchRates(), this.UPDATE_INTERVAL);
      
    } catch (error) {
      logger.warn('Failed to initialize exchange rates, using fallback rates', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  }

  private validateRate(rate: number): boolean {
    if (!rate || isNaN(rate) || rate <= 0) return false;

    const minRate = FALLBACK_RATES.USD_TO_NGN * (1 - this.RATE_THRESHOLD);
    const maxRate = FALLBACK_RATES.USD_TO_NGN * (1 + this.RATE_THRESHOLD);
    
    const isValid = rate >= minRate && rate <= maxRate;
    
    if (!isValid) {
      logger.warn('Exchange rate validation failed', {
        rate,
        minRate,
        maxRate,
        fallbackRate: FALLBACK_RATES.USD_TO_NGN
      });
    }
    
    return isValid;
  }

  private async fetchWithTimeout(url: string): Promise<Response> {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), this.FETCH_TIMEOUT);

    try {
      const response = await fetch(url, { 
        signal: controller.signal,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } finally {
      clearTimeout(timeout);
    }
  }

  private async fetchRates(): Promise<void> {
    if (this.isUpdating) return;

    this.isUpdating = true;
    try {
      const apiKey = import.meta.env.VITE_EXCHANGE_RATE_API_KEY;
      const baseUrl = import.meta.env.VITE_EXCHANGE_RATE_API_URL;

      if (!apiKey || !baseUrl) {
        return;
      }

      const url = `${baseUrl}${apiKey}/latest/USD`;
      const response = await this.fetchWithTimeout(url);
      const data = await response.json() as ExchangeRateAPIResponse;

      if (data.result !== 'success' || !data.conversion_rates?.NGN) {
        throw new Error('Invalid API response format');
      }

      const usdNgnRate = data.conversion_rates.NGN;
      
      if (!this.validateRate(usdNgnRate)) {
        throw new Error('Exchange rate outside acceptable range');
      }

      this.rates = {
        USD_TO_NGN: usdNgnRate,
        NGN_TO_USD: 1 / usdNgnRate,
        lastUpdated: new Date(data.time_last_update_utc),
        source: 'api'
      };

      this.retryCount = 0;

      logger.info('Exchange rates updated successfully', {
        rates: this.rates,
        source: 'api'
      });

    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      
      if (this.retryCount >= this.MAX_RETRIES) {
        logger.info('Using fallback rates after maximum retries', {
          rates: FALLBACK_RATES
        });
        return;
      }

      this.retryCount++;
      const delay = this.RETRY_DELAY * Math.pow(2, this.retryCount - 1);
      
      logger.info(`Retrying exchange rate fetch in ${delay}ms`, {
        attempt: this.retryCount,
        maxRetries: this.MAX_RETRIES
      });
      
      setTimeout(() => this.fetchRates(), delay);
    } finally {
      this.isUpdating = false;
    }
  }

  public convertUSDtoNGN(amount: number): number {
    return amount * (this.rates?.USD_TO_NGN || FALLBACK_RATES.USD_TO_NGN);
  }

  public convertNGNtoUSD(amount: number): number {
    return amount * (this.rates?.NGN_TO_USD || FALLBACK_RATES.NGN_TO_USD);
  }

  public getCurrentRates(): ExchangeRates {
    return this.rates || {
      USD_TO_NGN: FALLBACK_RATES.USD_TO_NGN,
      NGN_TO_USD: FALLBACK_RATES.NGN_TO_USD,
      lastUpdated: new Date(),
      source: 'fallback'
    };
  }

  public getLastUpdate(): { lastUpdated: Date; source: 'api' | 'fallback' } {
    return {
      lastUpdated: this.rates?.lastUpdated || new Date(),
      source: this.rates?.source || 'fallback'
    };
  }
}

export const forexService = ForexService.getInstance();