import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Button } from '../../../components/shared';
import { CheckCircle, Users, Clock, DollarSign } from 'lucide-react';

const emptyChartData = [
  { date: 'Mon', tasks: 0, amount: 0 },
  { date: 'Tue', tasks: 0, amount: 0 },
  { date: 'Wed', tasks: 0, amount: 0 },
  { date: 'Thu', tasks: 0, amount: 0 },
  { date: 'Fri', tasks: 0, amount: 0 },
  { date: 'Sat', tasks: 0, amount: 0 },
  { date: 'Sun', tasks: 0, amount: 0 }
];

export const BusinessOverview = () => {
  const navigate = useNavigate();

  const stats = [
    {
      name: 'Active Tasks',
      value: '0',
      change: 'No tasks yet',
      icon: CheckCircle
    },
    {
      name: 'Active Workers',
      value: '0',
      change: 'No workers yet',
      icon: Users
    },
    {
      name: 'Avg. Response Time',
      value: '0h',
      change: 'No data yet',
      icon: Clock
    },
    {
      name: 'Monthly Spend',
      value: '$0',
      change: 'No spending yet',
      icon: DollarSign
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header with Quick Actions */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Business Overview</h1>
          <p className="mt-1 text-gray-600">
            Monitor your task performance and worker activity
          </p>
        </div>
        <div className="flex gap-3">
          <Button 
            variant="primary"
            onClick={() => navigate('/dashboard/tasks')}
          >
            Post New Task
          </Button>
          <Button 
            variant="secondary"
            onClick={() => navigate('/dashboard/workers')}
          >
            Find Workers
          </Button>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => {
          const Icon = stat.icon;
          return (
            <div 
              key={stat.name} 
              className="bg-white p-6 rounded-xl shadow-sm"
            >
              <div className="flex items-center justify-between">
                <div className="bg-indigo-50 p-2 rounded-lg">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <span className="text-sm font-medium text-gray-500">
                  {stat.change}
                </span>
              </div>
              <p className="mt-4 text-2xl font-semibold text-gray-900">
                {stat.value}
              </p>
              <p className="mt-1 text-sm text-gray-500">{stat.name}</p>
            </div>
          );
        })}
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Task Performance
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={emptyChartData}>
              <defs>
                <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4F46E5" stopOpacity={0.1} />
                  <stop offset="95%" stopColor="#4F46E5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="date"
                tick={{ fill: '#6B7280' }}
                tickLine={false}
              />
              <YAxis
                yAxisId="left"
                tick={{ fill: '#6B7280' }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `$${value}`}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tick={{ fill: '#6B7280' }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value} tasks`}
              />
              <Tooltip />
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="amount"
                stroke="#4F46E5"
                fillOpacity={1}
                fill="url(#colorAmount)"
                name="amount"
              />
              <Area
                yAxisId="right"
                type="monotone"
                dataKey="tasks"
                stroke="#10B981"
                fill="none"
                name="tasks"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        {/* Empty state message */}
        <div className="text-center mt-4">
          <p className="text-gray-500">
            Post your first task to start tracking performance
          </p>
        </div>
      </div>
    </div>
  );
};