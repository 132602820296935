import React from 'react';
import { Container } from './shared';
import { Gamepad2, DollarSign, TrendingUp, Users, Trophy, Target } from 'lucide-react';

const features = [
  {
    icon: Gamepad2,
    title: 'Gamified Experience',
    description: 'Enjoy completing tasks with game-like elements, achievements, and rewards that make earning fun.'
  },
  {
    icon: DollarSign,
    title: 'Real Rewards',
    description: 'Earn actual money for your efforts with transparent payment systems and bonus multipliers.'
  },
  {
    icon: TrendingUp,
    title: 'Skill Growth',
    description: 'Level up your abilities and unlock new opportunities as you complete more challenging tasks.'
  },
  {
    icon: Users,
    title: 'Community',
    description: 'Join a vibrant community of earners, compete in challenges, and share your success.'
  },
  {
    icon: Trophy,
    title: 'Achievements',
    description: 'Unlock badges, rewards, and special bonuses as you reach new milestones.'
  },
  {
    icon: Target,
    title: 'Varied Tasks',
    description: 'Choose from multiple task types that match your interests and skills.'
  }
];

export const Features = () => {
  return (
    <section className="py-20" id="features">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Why Choose ToCash?</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Our platform combines earning opportunities with engaging experiences
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="group bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-1"
              >
                <div className="bg-indigo-50 w-12 h-12 rounded-lg flex items-center justify-center mb-4 group-hover:bg-indigo-100 transition-colors">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};