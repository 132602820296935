import { useState } from 'react';
import { paymentService } from '../services/PaymentService';
import { WithdrawalRequest, PaymentResponse } from '../types/payment';
import { useNotifications } from '../context/NotificationContext';
import { logger } from '../utils/logger';

export const usePayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addNotification } = useNotifications();

  const initiateWithdrawal = async (request: WithdrawalRequest): Promise<PaymentResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      // Validate request
      if (!request.amount || request.amount <= 0) {
        throw new Error('Invalid withdrawal amount');
      }

      if (!request.method) {
        throw new Error('Payment method is required');
      }

      // Verify account if needed
      const verified = await paymentService.verifyAccount(
        request.method,
        request.accountDetails
      );

      if (!verified) {
        throw new Error('Account verification failed');
      }

      // Process withdrawal
      const response = await paymentService.initiateWithdrawal(request);

      addNotification({
        type: 'success',
        title: 'Withdrawal Initiated',
        message: `Your withdrawal of ${request.currency} ${request.amount} has been initiated.`
      });

      return response;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Withdrawal failed';
      setError(message);
      
      addNotification({
        type: 'error',
        title: 'Withdrawal Failed',
        message
      });

      logger.error('Withdrawal failed', { error, request });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getBalance = async (): Promise<number> => {
    try {
      return await paymentService.getBalance();
    } catch (error) {
      logger.error('Failed to get balance', { error });
      throw error;
    }
  };

  const getPaymentMethods = async () => {
    try {
      return await paymentService.getPaymentMethods();
    } catch (error) {
      logger.error('Failed to get payment methods', { error });
      throw error;
    }
  };

  return {
    initiateWithdrawal,
    getBalance,
    getPaymentMethods,
    loading,
    error
  };
};