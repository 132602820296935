import React from 'react';
import { Container } from './shared';
import { ArrowRight } from 'lucide-react';

const steps = [
  {
    number: '01',
    title: 'Sign Up',
    description: 'Create your account and complete your profile'
  },
  {
    number: '02',
    title: 'Choose Tasks',
    description: 'Browse available tasks that match your interests'
  },
  {
    number: '03',
    title: 'Complete & Earn',
    description: 'Work on tasks and earn rewards for quality completion'
  },
  {
    number: '04',
    title: 'Level Up',
    description: 'Gain experience, unlock achievements, and earn more'
  }
];

export const HowItWorks = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-indigo-50" id="how-it-works">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">How It Works</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Start earning in four simple steps
          </p>
        </div>
        <div className="grid md:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group hover:-translate-y-1">
                <div className="w-12 h-12 rounded-full bg-indigo-600 text-white flex items-center justify-center mx-auto mb-4 text-xl font-bold group-hover:scale-110 transition-transform">
                  {step.number}
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center text-gray-900">{step.title}</h3>
                <p className="text-gray-600 text-center">{step.description}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-1/2 -right-4 transform -translate-y-1/2">
                  <ArrowRight className="h-6 w-6 text-indigo-600" />
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};