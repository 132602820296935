import React from 'react';
import { Container } from './shared';
import { Trophy, Users, Star, TrendingUp, Medal, Award } from 'lucide-react';

const successStories = [
  {
    id: 1,
    name: 'David Chen',
    role: 'Student',
    image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80',
    story: 'Started as a beginner, now earning $1000/month while studying. ToCash helped me develop professional skills and earn simultaneously.',
    earnings: '12,450',
    tasksCompleted: 384,
    rating: 4.9
  },
  {
    id: 2,
    name: 'Maria Garcia',
    role: 'Freelancer',
    image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80',
    story: 'ToCash provided me the flexibility to work from anywhere. I\'ve completed over 500 tasks and built a reliable income stream.',
    earnings: '18,750',
    tasksCompleted: 523,
    rating: 4.95
  },
  {
    id: 3,
    name: 'James Wilson',
    role: 'Part-time Worker',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80',
    story: 'The gamified system keeps me motivated. I\'ve reached the top tier and now earn premium rates for specialized tasks.',
    earnings: '15,200',
    tasksCompleted: 445,
    rating: 4.85
  }
];

const topPerformers = [
  {
    rank: 1,
    name: 'Sarah Johnson',
    points: 15420,
    tasksCompleted: 642,
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80'
  },
  {
    rank: 2,
    name: 'Michael Chang',
    points: 14850,
    tasksCompleted: 589,
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80'
  },
  {
    rank: 3,
    name: 'Emma Davis',
    points: 14200,
    tasksCompleted: 567,
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&h=256&q=80'
  }
];

const communityStats = [
  {
    icon: Users,
    label: 'Active Members',
    value: '50K+',
    change: '+12% this month'
  },
  {
    icon: Trophy,
    label: 'Tasks Completed',
    value: '1M+',
    change: '+8% this month'
  },
  {
    icon: Star,
    label: 'Average Rating',
    value: '4.9/5',
    change: '+0.2 this month'
  },
  {
    icon: TrendingUp,
    label: 'Total Earnings',
    value: '$5M+',
    change: '+15% this month'
  }
];

export const Community = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-indigo-50 to-white" id="community">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Thriving Community</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Join thousands of successful members who are earning and growing together
          </p>
        </div>

        {/* Community Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {communityStats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="bg-indigo-50 p-3 rounded-lg">
                    <Icon className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div>
                    <p className="text-2xl font-bold text-gray-900">{stat.value}</p>
                    <p className="text-sm text-gray-500">{stat.label}</p>
                  </div>
                </div>
                <p className="text-sm text-green-600">{stat.change}</p>
              </div>
            );
          })}
        </div>

        {/* Success Stories */}
        <div className="mb-16">
          <div className="text-center mb-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Success Stories</h3>
            <p className="text-gray-600">Real stories from our community members</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {successStories.map((story) => (
              <div
                key={story.id}
                className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
              >
                <div className="p-6">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={story.image}
                      alt={story.name}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-semibold text-lg">{story.name}</h4>
                      <p className="text-gray-600">{story.role}</p>
                    </div>
                  </div>
                  <p className="text-gray-600 mb-4">{story.story}</p>
                  <div className="grid grid-cols-3 gap-4 pt-4 border-t">
                    <div className="text-center">
                      <p className="text-sm text-gray-500">Earned</p>
                      <p className="font-semibold text-indigo-600">${story.earnings}</p>
                    </div>
                    <div className="text-center">
                      <p className="text-sm text-gray-500">Tasks</p>
                      <p className="font-semibold text-indigo-600">{story.tasksCompleted}</p>
                    </div>
                    <div className="text-center">
                      <p className="text-sm text-gray-500">Rating</p>
                      <p className="font-semibold text-indigo-600">{story.rating}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Leaderboard Preview */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h3 className="text-2xl font-bold text-gray-900">Top Performers</h3>
                <p className="text-gray-600">This month's leading contributors</p>
              </div>
              <a
                href="/leaderboard"
                className="text-indigo-600 hover:text-indigo-700 font-medium"
              >
                View Full Leaderboard
              </a>
            </div>

            <div className="space-y-4">
              {topPerformers.map((performer) => (
                <div
                  key={performer.rank}
                  className="flex items-center justify-between p-4 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-50">
                      {performer.rank === 1 && <Trophy className="w-5 h-5 text-yellow-500" />}
                      {performer.rank === 2 && <Medal className="w-5 h-5 text-gray-400" />}
                      {performer.rank === 3 && <Award className="w-5 h-5 text-amber-600" />}
                    </div>
                    <img
                      src={performer.avatar}
                      alt={performer.name}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                    <div>
                      <p className="font-medium text-gray-900">{performer.name}</p>
                      <p className="text-sm text-gray-500">
                        {performer.tasksCompleted} tasks completed
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold text-indigo-600">
                      {performer.points.toLocaleString()} pts
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};