import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../LoadingScreen';

export const AuthCallback = () => {
  const { error, isLoading, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error('Auth Error:', error);
        navigate('/', { replace: true });
      } else if (isAuthenticated) {
        const userType = user?.['https://tocash.io/user_type'];
        if (!userType) {
          navigate('/user-type-selection', { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }
      }
    }
  }, [isLoading, error, isAuthenticated, user, navigate]);

  return <LoadingScreen />;
};