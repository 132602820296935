import { TaskSubmission } from '../types/task';
import { automatedVerificationService } from './AutomatedVerificationService';
import { taskService } from './TaskService';
import { earningsService } from './EarningsService';
import { logger } from '../utils/logger';

class BatchApprovalService {
  private static instance: BatchApprovalService;

  private constructor() {}

  public static getInstance(): BatchApprovalService {
    if (!BatchApprovalService.instance) {
      BatchApprovalService.instance = new BatchApprovalService();
    }
    return BatchApprovalService.instance;
  }

  async processBatchApproval(
    submissions: TaskSubmission[],
    options: {
      autoApproveThreshold?: number;
      manualReviewThreshold?: number;
      maxBatchSize?: number;
    } = {}
  ): Promise<{
    approved: TaskSubmission[];
    rejected: TaskSubmission[];
    needsReview: TaskSubmission[];
    errors: { submission: TaskSubmission; error: string }[];
  }> {
    const {
      autoApproveThreshold = 0.9,
      manualReviewThreshold = 0.7,
      maxBatchSize = 100
    } = options;

    const results = {
      approved: [] as TaskSubmission[],
      rejected: [] as TaskSubmission[],
      needsReview: [] as TaskSubmission[],
      errors: [] as { submission: TaskSubmission; error: string }[]
    };

    // Process submissions in chunks to avoid memory issues
    const chunks = this.chunkArray(submissions, maxBatchSize);

    for (const chunk of chunks) {
      await Promise.all(
        chunk.map(async (submission) => {
          try {
            const verification = await automatedVerificationService.verifySubmission(submission);

            if (verification.confidence >= autoApproveThreshold) {
              // Auto-approve high-confidence submissions
              await this.approveSubmission(submission);
              results.approved.push(submission);
            } else if (verification.confidence >= manualReviewThreshold) {
              // Flag for manual review if confidence is moderate
              results.needsReview.push(submission);
            } else {
              // Auto-reject low-confidence submissions
              await this.rejectSubmission(submission, verification.reason);
              results.rejected.push(submission);
            }
          } catch (error) {
            logger.error('Batch approval failed for submission', {
              error,
              submissionId: submission.id
            });
            results.errors.push({
              submission,
              error: error instanceof Error ? error.message : 'Unknown error'
            });
          }
        })
      );
    }

    return results;
  }

  private async approveSubmission(submission: TaskSubmission): Promise<void> {
    try {
      // Update submission status
      await taskService.reviewSubmission(
        submission.id,
        true,
        'Automatically approved by system'
      );

      // Credit earnings to user
      await earningsService.creditEarnings(
        submission.userId,
        submission.taskId
      );

      logger.info('Submission approved and earnings credited', {
        submissionId: submission.id,
        userId: submission.userId,
        taskId: submission.taskId
      });
    } catch (error) {
      logger.error('Failed to process approved submission', {
        error,
        submissionId: submission.id
      });
      throw error;
    }
  }

  private async rejectSubmission(
    submission: TaskSubmission,
    reason?: string
  ): Promise<void> {
    try {
      await taskService.reviewSubmission(
        submission.id,
        false,
        reason || 'Automatically rejected by system'
      );

      logger.info('Submission rejected', {
        submissionId: submission.id,
        reason
      });
    } catch (error) {
      logger.error('Failed to process rejected submission', {
        error,
        submissionId: submission.id
      });
      throw error;
    }
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }
}

export const batchApprovalService = BatchApprovalService.getInstance();