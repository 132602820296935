import React, { useState, useEffect, useRef } from 'react';
import { Bell } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFirebase } from '../context/FirebaseContext';
import { notificationService } from '../services/NotificationService';
import { useClickOutside } from '../hooks/useClickOutside';
import { Button } from './shared';

export const NotificationBell: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth0();
  const { 
    notificationsSupported, 
    notificationPermission,
    requestNotificationPermission 
  } = useFirebase();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (!user?.sub) return;

    // Load initial notifications
    loadNotifications();

    // Subscribe to real-time updates
    const unsubscribe = notificationService.subscribeToUserNotifications(
      user.sub,
      handleNewNotification
    );

    return () => unsubscribe();
  }, [user]);

  const loadNotifications = async () => {
    if (!user?.sub) return;
    try {
      const userNotifications = await notificationService.getUserNotifications(user.sub);
      setNotifications(userNotifications);
      const count = await notificationService.getUnreadCount(user.sub);
      setUnreadCount(count);
    } catch (error) {
      console.error('Failed to load notifications:', error);
    }
  };

  const handleNewNotification = (notification: any) => {
    setNotifications(prev => [notification, ...prev]);
    setUnreadCount(prev => prev + 1);
  };

  const handlePermissionRequest = async () => {
    if (!notificationsSupported) return;

    const granted = await requestNotificationPermission();
    if (granted) {
      loadNotifications();
    }
  };

  const handleMarkAsRead = async (notificationId: string) => {
    try {
      await notificationService.markAsRead(notificationId);
      setNotifications(prev =>
        prev.map(n =>
          n.id === notificationId ? { ...n, read: true } : n
        )
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-lg"
      >
        <Bell className="h-6 w-6" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {notificationsSupported && notificationPermission === 'default' && !isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg p-4">
          <p className="text-sm text-gray-600 mb-3">
            Enable notifications to stay updated on your tasks and earnings.
          </p>
          <Button
            variant="primary"
            size="sm"
            onClick={handlePermissionRequest}
            className="w-full"
          >
            Enable Notifications
          </Button>
        </div>
      )}

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-1">
          {notifications.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              No notifications
            </div>
          ) : (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className={`p-4 hover:bg-gray-50 cursor-pointer ${
                  !notification.read ? 'bg-indigo-50' : ''
                }`}
                onClick={() => handleMarkAsRead(notification.id)}
              >
                <h4 className="font-medium text-gray-900">{notification.title}</h4>
                <p className="text-sm text-gray-600">{notification.message}</p>
                <p className="text-xs text-gray-500 mt-1">
                  {new Date(notification.createdAt).toLocaleString()}
                </p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};