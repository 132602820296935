import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LandingPage } from './pages/LandingPage';
import { Dashboard } from './pages/Dashboard';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthCallback } from './components/auth/AuthCallback';
import { UserTypeSelection } from './components/auth/UserTypeSelection';
import { LoadingScreen } from './components/LoadingScreen';

export const AppRoutes = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/callback" element={<AuthCallback />} />
      <Route 
        path="/user-type-selection" 
        element={
          isAuthenticated && !user?.['https://tocash.io/user_type'] ? (
            <UserTypeSelection />
          ) : (
            <Navigate to={isAuthenticated ? '/dashboard' : '/'} replace />
          )
        } 
      />
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};