import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { DashboardLayout } from './DashboardLayout';
import { BusinessDashboard } from './business';
import { IndividualDashboard } from './individual';
import { LoadingScreen } from '../../components/LoadingScreen';

export const Dashboard = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/" replace />;
  }

  // Determine user type from Auth0 metadata
  const userType = user['https://tocash.io/user_type'] || 'individual';

  return (
    <DashboardLayout>
      {userType === 'business' ? (
        <BusinessDashboard />
      ) : (
        <IndividualDashboard />
      )}
    </DashboardLayout>
  );
};