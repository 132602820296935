import React from 'react';
import { DollarSign } from 'lucide-react';
import { Button } from '../../../components/shared';

const transactions = [
  {
    id: 'tx_1',
    description: 'Task Payment #1',
    date: '2024-03-01',
    amount: 1000
  },
  {
    id: 'tx_2',
    description: 'Task Payment #2',
    date: '2024-03-02',
    amount: 2000
  },
  {
    id: 'tx_3',
    description: 'Task Payment #3',
    date: '2024-03-03',
    amount: 3000
  }
].map(tx => ({
  ...tx,
  amount: tx.amount / 100 // Convert cents to dollars
}));

export const BusinessBilling = () => {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Billing & Payments</h1>
        <p className="mt-1 text-gray-600">
          Manage your payment methods and view transactions
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">
            Recent Transactions
          </h2>
          <Button variant="secondary" size="sm">
            View All
          </Button>
        </div>

        <div className="space-y-4">
          {transactions.map((transaction) => (
            <div
              key={transaction.id}
              className="flex items-center justify-between p-4 border rounded-lg"
            >
              <div>
                <p className="font-medium text-gray-900">
                  {transaction.description}
                </p>
                <p className="text-sm text-gray-600">
                  {new Date(transaction.date).toLocaleDateString()}
                </p>
              </div>
              <span className="font-medium text-gray-900">
                ${transaction.amount.toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};