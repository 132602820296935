import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  orderBy, 
  limit, 
  getDocs,
  onSnapshot,
  serverTimestamp,
  Unsubscribe 
} from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { logger } from '../utils/logger';

export interface TaskNotification {
  id: string;
  userId: string;
  type: 'task_assigned' | 'task_completed' | 'submission_reviewed' | 'payment_processed';
  title: string;
  message: string;
  read: boolean;
  taskId?: string;
  createdAt: Date;
}

class NotificationService {
  private static instance: NotificationService;
  private readonly NOTIFICATIONS_COLLECTION = 'notifications';
  private readonly VAPID_KEY = import.meta.env.VITE_FIREBASE_VAPID_KEY;
  private notificationCallbacks: ((notification: TaskNotification) => void)[] = [];
  private fcmToken: string | null = null;
  private retryCount = 0;
  private readonly MAX_RETRIES = 3;
  private readonly RETRY_DELAY = 2000; // 2 seconds

  private constructor() {
    this.initializeFCM();
  }

  public static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  private async initializeFCM() {
    try {
      if (!('Notification' in window)) {
        logger.info('This browser does not support notifications');
        return;
      }

      if (Notification.permission === 'denied') {
        logger.info('Notifications are blocked by user');
        return;
      }

      const messaging = getMessaging();
      
      // Handle foreground messages
      onMessage(messaging, (payload) => {
        logger.info('Received foreground message:', payload);
        this.handleIncomingMessage(payload);
      });

      // Only get token if permission is granted
      if (Notification.permission === 'granted') {
        await this.getFCMToken();
      }
    } catch (error) {
      this.handleFCMError(error);
    }
  }

  private async getFCMToken(retry = false): Promise<string | null> {
    try {
      const messaging = getMessaging();
      const token = await getToken(messaging, {
        vapidKey: this.VAPID_KEY
      });

      if (token) {
        this.fcmToken = token;
        logger.info('FCM Token obtained successfully');
        return token;
      }

      throw new Error('Failed to get FCM token');
    } catch (error) {
      if (retry && this.retryCount < this.MAX_RETRIES) {
        this.retryCount++;
        logger.warn(`Retrying FCM token retrieval (${this.retryCount}/${this.MAX_RETRIES})`);
        await new Promise(resolve => setTimeout(resolve, this.RETRY_DELAY * this.retryCount));
        return this.getFCMToken(true);
      }

      this.handleFCMError(error);
      return null;
    }
  }

  private handleFCMError(error: any) {
    if (error.code === 'messaging/permission-blocked') {
      logger.info('Notifications are blocked by user');
    } else if (error.code === 'messaging/unsupported-browser') {
      logger.info('Browser does not support Firebase Cloud Messaging');
    } else {
      logger.error('FCM initialization failed:', error);
    }
  }

  private handleIncomingMessage(payload: any) {
    const notification: TaskNotification = {
      id: payload.data.notificationId,
      userId: payload.data.userId,
      type: payload.data.type,
      title: payload.notification.title,
      message: payload.notification.body,
      read: false,
      taskId: payload.data.taskId,
      createdAt: new Date()
    };

    this.notifyCallbacks(notification);
  }

  async createNotification(
    userId: string,
    notification: Omit<TaskNotification, 'id' | 'createdAt' | 'read'>
  ): Promise<string> {
    try {
      const docRef = await addDoc(collection(db, this.NOTIFICATIONS_COLLECTION), {
        ...notification,
        userId,
        read: false,
        createdAt: serverTimestamp()
      });

      return docRef.id;
    } catch (error) {
      logger.error('Failed to create notification:', error);
      throw error;
    }
  }

  subscribeToUserNotifications(
    userId: string,
    callback: (notification: TaskNotification) => void
  ): Unsubscribe {
    this.notificationCallbacks.push(callback);

    const q = query(
      collection(db, this.NOTIFICATIONS_COLLECTION),
      where('userId', '==', userId),
      where('read', '==', false),
      orderBy('createdAt', 'desc')
    );

    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const notification = {
            id: change.doc.id,
            ...change.doc.data(),
            createdAt: change.doc.data().createdAt?.toDate()
          } as TaskNotification;
          
          this.notifyCallbacks(notification);
        }
      });
    });
  }

  private notifyCallbacks(notification: TaskNotification) {
    this.notificationCallbacks.forEach(callback => callback(notification));
  }

  async getFCMTokenWithRetry(): Promise<string | null> {
    return this.getFCMToken(true);
  }
}

export const notificationService = NotificationService.getInstance();