import { LRUCache } from 'lru-cache';
import { logger } from '../utils/logger';

interface CacheOptions {
  max?: number;
  ttl?: number;
}

class CacheService {
  private static instance: CacheService;
  private caches: Map<string, LRUCache<string, any>>;

  private constructor() {
    this.caches = new Map();
  }

  public static getInstance(): CacheService {
    if (!CacheService.instance) {
      CacheService.instance = new CacheService();
    }
    return CacheService.instance;
  }

  createCache(name: string, options: CacheOptions = {}) {
    const cache = new LRUCache({
      max: options.max || 100,
      ttl: options.ttl || 1000 * 60 * 5, // 5 minutes default
      updateAgeOnGet: true,
      updateAgeOnHas: true
    });

    this.caches.set(name, cache);
    return cache;
  }

  getCache(name: string): LRUCache<string, any> | undefined {
    return this.caches.get(name);
  }

  getOrCreateCache(name: string, options?: CacheOptions): LRUCache<string, any> {
    let cache = this.getCache(name);
    if (!cache) {
      cache = this.createCache(name, options);
    }
    return cache;
  }

  async getOrSet<T>(
    cacheName: string,
    key: string,
    fetcher: () => Promise<T>,
    options?: CacheOptions
  ): Promise<T> {
    const cache = this.getOrCreateCache(cacheName, options);
    const cacheKey = this.generateKey(key);

    try {
      // Check cache first
      const cached = cache.get(cacheKey);
      if (cached !== undefined) {
        logger.debug('Cache hit', { cacheName, key });
        return cached as T;
      }

      // Fetch and cache
      logger.debug('Cache miss', { cacheName, key });
      const data = await fetcher();
      cache.set(cacheKey, data);
      return data;
    } catch (error) {
      logger.error('Cache operation failed', { error, cacheName, key });
      throw error;
    }
  }

  invalidate(cacheName: string, key?: string): void {
    const cache = this.getCache(cacheName);
    if (!cache) return;

    if (key) {
      cache.delete(this.generateKey(key));
    } else {
      cache.clear();
    }
  }

  private generateKey(key: string): string {
    return typeof key === 'string' ? key : JSON.stringify(key);
  }
}

export const cacheService = CacheService.getInstance();