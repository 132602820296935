import React, { useState, useEffect } from 'react';
import { X, Star, CheckCircle, Clock, Calendar } from 'lucide-react';
import { Button } from '../shared';
import { Task } from '../../types/task';
import { taskService } from '../../services/TaskService';
import { useNotifications } from '../../context/NotificationContext';

interface Worker {
  id: string;
  name: string;
  avatar: string;
  level: number;
  rating: number;
  tasksCompleted: number;
  expertise: string[];
  joinedDate: string;
  status: 'active' | 'inactive';
  completionRate: number;
  avgResponseTime: string;
}

interface WorkerDetailsModalProps {
  worker: Worker | null;
  onClose: () => void;
}

export const WorkerDetailsModal: React.FC<WorkerDetailsModalProps> = ({
  worker,
  onClose
}) => {
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableTasks, setAvailableTasks] = useState<Task[]>([]);
  const { addNotification } = useNotifications();

  useEffect(() => {
    const loadTasks = async () => {
      if (!worker) return;
      try {
        const tasks = await taskService.getAvailableTasks();
        setAvailableTasks(tasks.filter(task => 
          task.status === 'open' && worker.expertise.some(exp => 
            exp.toLowerCase() === task.category.replace('_', ' ').toLowerCase()
          )
        ));
      } catch (error) {
        addNotification({
          type: 'error',
          title: 'Error',
          message: 'Failed to load available tasks'
        });
      }
    };
    loadTasks();
  }, [worker, addNotification]);

  const handleAssignTask = async () => {
    if (!worker || !selectedTask) return;

    setIsLoading(true);
    try {
      await taskService.assignTask(selectedTask.id, worker.id);
      addNotification({
        type: 'success',
        title: 'Task Assigned',
        message: `Task successfully assigned to ${worker.name}`
      });
      onClose();
    } catch (error: any) {
      addNotification({
        type: 'error',
        title: 'Assignment Failed',
        message: error.message || 'Failed to assign task'
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!worker) return null;

  const metrics = [
    {
      icon: CheckCircle,
      label: 'Tasks Completed',
      value: worker.tasksCompleted
    },
    {
      icon: Star,
      label: 'Rating',
      value: worker.rating
    },
    {
      icon: CheckCircle,
      label: 'Completion Rate',
      value: `${worker.completionRate}%`
    },
    {
      icon: Clock,
      label: 'Avg. Response Time',
      value: worker.avgResponseTime
    }
  ];

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <img
                src={worker.avatar}
                alt={worker.name}
                className="w-16 h-16 rounded-full object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {worker.name}
                </h2>
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <span>Level {worker.level}</span>
                  <span>•</span>
                  <div className="flex items-center gap-1">
                    <Calendar className="h-4 w-4" />
                    <span>Joined {new Date(worker.joinedDate).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {metrics.map((metric) => {
              const Icon = metric.icon;
              return (
                <div
                  key={metric.label}
                  className="bg-gray-50 p-4 rounded-lg"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <Icon className="h-5 w-5 text-indigo-600" />
                    <span className="text-sm text-gray-500">{metric.label}</span>
                  </div>
                  <p className="text-xl font-semibold text-gray-900">
                    {metric.value}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-3">
              Expertise
            </h3>
            <div className="flex flex-wrap gap-2">
              {worker.expertise.map((skill) => (
                <span
                  key={skill}
                  className="px-3 py-1 bg-indigo-50 text-indigo-600 rounded-full text-sm"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>

          {availableTasks.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">
                Assign Task
              </h3>
              <select
                value={selectedTask?.id || ''}
                onChange={(e) => {
                  const task = availableTasks.find(t => t.id === e.target.value);
                  setSelectedTask(task || null);
                }}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select a task...</option>
                {availableTasks.map((task) => (
                  <option key={task.id} value={task.id}>
                    {task.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            {availableTasks.length > 0 && (
              <Button
                variant="primary"
                onClick={handleAssignTask}
                disabled={!selectedTask || isLoading}
              >
                {isLoading ? 'Assigning...' : 'Assign Task'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};