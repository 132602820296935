import React from 'react';
import { Container } from './shared';
import { Github, Twitter, Linkedin, Mail } from 'lucide-react';
import { Logo, LogoIcon } from './Logo';

const footerLinks = {
  product: [
    { label: 'Features', href: '#features' },
    { label: 'How it Works', href: '#how-it-works' },
    { label: 'Pricing', href: '#pricing' },
    { label: 'FAQ', href: '#faq' },
  ],
  company: [
    { label: 'About Us', href: '#' },
    { label: 'Careers', href: '#' },
    { label: 'Blog', href: '#' },
    { label: 'Press Kit', href: '#' },
  ],
  legal: [
    { label: 'Terms', href: '#' },
    { label: 'Privacy', href: '#' },
    { label: 'Cookies', href: '#' },
    { label: 'Licenses', href: '#' },
  ],
  support: [
    { label: 'Help Center', href: '#' },
    { label: 'Contact', href: '#' },
    { label: 'Status', href: '#' },
    { label: 'API', href: '#' },
  ],
};

const socialLinks = [
  { Icon: Twitter, href: '#', label: 'Twitter' },
  { Icon: Github, href: '#', label: 'GitHub' },
  { Icon: Linkedin, href: '#', label: 'LinkedIn' },
  { Icon: Mail, href: '#', label: 'Email' },
];

export const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400">
      <Container>
        <div className="py-12">
          <div className="grid grid-cols-2 md:grid-cols-6 gap-8 mb-8">
            <div className="col-span-2">
              <div className="flex items-center space-x-2 text-white mb-4">
                <LogoIcon className="h-8 w-8 text-indigo-400" />
                <span className="text-2xl font-bold">ToCash</span>
              </div>
              <p className="mb-4">
                Transform your time into earnings through engaging tasks and challenges.
              </p>
              <div className="flex space-x-4">
                {socialLinks.map(({ Icon, href, label }) => (
                  <a
                    key={label}
                    href={href}
                    className="text-gray-400 hover:text-white transition-colors"
                    aria-label={label}
                  >
                    <Icon className="h-5 w-5" />
                  </a>
                ))}
              </div>
            </div>
            
            {Object.entries(footerLinks).map(([title, links]) => (
              <div key={title} className="col-span-1">
                <h3 className="text-white font-semibold mb-3 capitalize">{title}</h3>
                <ul className="space-y-2">
                  {links.map((link) => (
                    <li key={link.label}>
                      <a
                        href={link.href}
                        className="hover:text-white transition-colors"
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          
          <div className="border-t border-gray-800 pt-8 mt-8 text-center">
            <p>&copy; {new Date().getFullYear()} ToCash. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};