import { db } from './firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  addDoc, 
  updateDoc, 
  query, 
  where,
  orderBy,
  limit,
  startAfter,
  Timestamp,
  serverTimestamp,
  getCountFromServer,
  QueryConstraint
} from 'firebase/firestore';
import { Task, TaskSubmission, PaginatedResponse } from '../types/task';
import { cacheService } from './CacheService';
import { logger } from '../utils/logger';

class TaskService {
  private static instance: TaskService;
  private readonly TASKS_COLLECTION = 'tasks';
  private readonly SUBMISSIONS_COLLECTION = 'task_submissions';
  private readonly CACHE_TTL = 1000 * 60 * 5; // 5 minutes
  private readonly PAGE_SIZE = 10;

  private constructor() {}

  public static getInstance(): TaskService {
    if (!TaskService.instance) {
      TaskService.instance = new TaskService();
    }
    return TaskService.instance;
  }

  async getAvailableTasks(
    page: number = 1,
    pageSize: number = this.PAGE_SIZE,
    lastDoc?: any,
    filters?: Record<string, any>
  ): Promise<PaginatedResponse<Task>> {
    const cacheKey = `tasks_${page}_${pageSize}_${JSON.stringify(filters)}`;

    return cacheService.getOrSet(
      'tasks',
      cacheKey,
      async () => {
        try {
          // Build query constraints
          const constraints: QueryConstraint[] = [
            where('status', '==', 'open'),
            orderBy('createdAt', 'desc')
          ];

          // Add filters if provided
          if (filters) {
            Object.entries(filters).forEach(([key, value]) => {
              if (value) {
                constraints.push(where(key, '==', value));
              }
            });
          }

          // Create base query
          let q = query(collection(db, this.TASKS_COLLECTION), ...constraints);

          // Get total count
          const countSnapshot = await getCountFromServer(q);
          const total = countSnapshot.data().count;

          // Apply pagination
          q = query(q, limit(pageSize));
          if (lastDoc) {
            q = query(q, startAfter(lastDoc));
          }

          const snapshot = await getDocs(q);
          const tasks = snapshot.docs.map(doc => 
            this.mapTaskFromFirestore(doc.id, doc.data())
          );

          return {
            items: tasks,
            total,
            page,
            pageSize,
            hasMore: tasks.length === pageSize,
            lastDoc: snapshot.docs[snapshot.docs.length - 1]
          };
        } catch (error) {
          logger.error('Failed to get available tasks:', error);
          throw error;
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async getTaskById(taskId: string): Promise<Task | null> {
    return cacheService.getOrSet(
      'tasks',
      `task_${taskId}`,
      async () => {
        try {
          const docRef = doc(db, this.TASKS_COLLECTION, taskId);
          const docSnap = await getDoc(docRef);
          
          if (!docSnap.exists()) {
            return null;
          }

          return this.mapTaskFromFirestore(docSnap.id, docSnap.data());
        } catch (error) {
          logger.error('Failed to get task by ID:', error);
          throw error;
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async createTask(task: Omit<Task, 'id' | 'createdAt' | 'updatedAt'>): Promise<Task> {
    try {
      const taskData = {
        ...task,
        status: 'pending',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      const docRef = await addDoc(collection(db, this.TASKS_COLLECTION), taskData);
      const newTask = await this.getTaskById(docRef.id);

      if (!newTask) {
        throw new Error('Failed to create task');
      }

      // Invalidate tasks cache
      cacheService.invalidate('tasks');

      return newTask;
    } catch (error) {
      logger.error('Failed to create task:', error);
      throw error;
    }
  }

  async editTask(taskId: string, updates: Partial<Task>): Promise<Task> {
    try {
      const docRef = doc(db, this.TASKS_COLLECTION, taskId);
      await updateDoc(docRef, {
        ...updates,
        updatedAt: serverTimestamp()
      });

      // Invalidate specific task and tasks list cache
      cacheService.invalidate('tasks', `task_${taskId}`);
      cacheService.invalidate('tasks');

      const updatedTask = await this.getTaskById(taskId);
      if (!updatedTask) {
        throw new Error('Task not found after update');
      }

      return updatedTask;
    } catch (error) {
      logger.error('Failed to edit task:', error);
      throw error;
    }
  }

  async assignTask(taskId: string, workerId: string): Promise<Task> {
    try {
      const docRef = doc(db, this.TASKS_COLLECTION, taskId);
      await updateDoc(docRef, {
        assignedTo: workerId,
        status: 'in_progress',
        updatedAt: serverTimestamp()
      });

      // Invalidate caches
      cacheService.invalidate('tasks', `task_${taskId}`);
      cacheService.invalidate('tasks');

      const updatedTask = await this.getTaskById(taskId);
      if (!updatedTask) {
        throw new Error('Task not found after assignment');
      }

      return updatedTask;
    } catch (error) {
      logger.error('Failed to assign task:', error);
      throw error;
    }
  }

  async submitTask(submission: Omit<TaskSubmission, 'id' | 'submittedAt' | 'status'>): Promise<void> {
    try {
      await addDoc(collection(db, this.SUBMISSIONS_COLLECTION), {
        ...submission,
        status: 'pending',
        submittedAt: serverTimestamp()
      });

      // Update task status
      const taskRef = doc(db, this.TASKS_COLLECTION, submission.taskId);
      await updateDoc(taskRef, {
        status: 'under_review',
        updatedAt: serverTimestamp()
      });

      // Invalidate caches
      cacheService.invalidate('tasks', `task_${submission.taskId}`);
      cacheService.invalidate('tasks');
      cacheService.invalidate('submissions');
    } catch (error) {
      logger.error('Failed to submit task:', error);
      throw error;
    }
  }

  async getTaskSubmissions(taskId: string): Promise<TaskSubmission[]> {
    return cacheService.getOrSet(
      'submissions',
      `task_${taskId}_submissions`,
      async () => {
        try {
          const q = query(
            collection(db, this.SUBMISSIONS_COLLECTION),
            where('taskId', '==', taskId),
            orderBy('submittedAt', 'desc')
          );

          const snapshot = await getDocs(q);
          return snapshot.docs.map(doc => 
            this.mapSubmissionFromFirestore(doc.id, doc.data())
          );
        } catch (error) {
          logger.error('Failed to get task submissions:', error);
          throw error;
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async getPendingSubmissions(): Promise<TaskSubmission[]> {
    return cacheService.getOrSet(
      'submissions',
      'pending_submissions',
      async () => {
        try {
          const q = query(
            collection(db, this.SUBMISSIONS_COLLECTION),
            where('status', '==', 'pending'),
            orderBy('submittedAt', 'desc')
          );

          const snapshot = await getDocs(q);
          return snapshot.docs.map(doc => 
            this.mapSubmissionFromFirestore(doc.id, doc.data())
          );
        } catch (error) {
          logger.error('Failed to get pending submissions:', error);
          throw error;
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async getPendingSubmissionCount(): Promise<number> {
    return cacheService.getOrSet(
      'submissions',
      'pending_count',
      async () => {
        try {
          const q = query(
            collection(db, this.SUBMISSIONS_COLLECTION),
            where('status', '==', 'pending')
          );
          
          const snapshot = await getCountFromServer(q);
          return snapshot.data().count;
        } catch (error) {
          logger.error('Failed to get pending submission count:', error);
          throw error;
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  private mapTaskFromFirestore(id: string, data: any): Task {
    return {
      id,
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
      deadline: data.deadline?.toDate() || new Date()
    };
  }

  private mapSubmissionFromFirestore(id: string, data: any): TaskSubmission {
    return {
      id,
      ...data,
      submittedAt: data.submittedAt?.toDate() || new Date(),
      reviewedAt: data.reviewedAt?.toDate(),
      socialMediaProof: data.socialMediaProof ? {
        ...data.socialMediaProof,
        timestamp: data.socialMediaProof.timestamp?.toDate() || new Date()
      } : undefined
    };
  }
}

export const taskService = TaskService.getInstance();