import React from 'react';
import { Star, CheckCircle, Clock } from 'lucide-react';

interface Worker {
  id: string;
  name: string;
  avatar: string;
  level: number;
  rating: number;
  tasksCompleted: number;
  expertise: string[];
  status: 'active' | 'inactive';
  completionRate: number;
  avgResponseTime: string;
}

interface WorkerCardProps {
  worker: Worker;
  onClick: () => void;
}

export const WorkerCard: React.FC<WorkerCardProps> = ({ worker, onClick }) => {
  return (
    <div
      className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="p-6">
        <div className="flex items-center gap-4 mb-4">
          <img
            src={worker.avatar}
            alt={worker.name}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h3 className="font-semibold text-gray-900">{worker.name}</h3>
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <span>Level {worker.level}</span>
              <span>•</span>
              <div className="flex items-center gap-1">
                <Star className="h-4 w-4 text-yellow-400 fill-current" />
                <span>{worker.rating}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex flex-wrap gap-2">
            {worker.expertise.map((skill) => (
              <span
                key={skill}
                className="px-2 py-1 bg-indigo-50 text-indigo-600 rounded-full text-sm"
              >
                {skill}
              </span>
            ))}
          </div>

          <div className="grid grid-cols-2 gap-4 pt-4 border-t">
            <div>
              <p className="text-sm text-gray-500">Tasks Completed</p>
              <p className="font-semibold text-gray-900">{worker.tasksCompleted}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Completion Rate</p>
              <p className="font-semibold text-gray-900">{worker.completionRate}%</p>
            </div>
          </div>

          <div className="flex items-center justify-between pt-4 border-t">
            <div className="flex items-center gap-1 text-sm">
              <Clock className="h-4 w-4 text-gray-400" />
              <span className="text-gray-500">Avg. Response: {worker.avgResponseTime}</span>
            </div>
            <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
              worker.status === 'active'
                ? 'bg-green-100 text-green-800'
                : 'bg-gray-100 text-gray-800'
            }`}>
              {worker.status === 'active' && (
                <CheckCircle className="h-3 w-3 mr-1" />
              )}
              {worker.status}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};