import { logger } from '../utils/logger';
import { taskService } from './TaskService';

interface EarningTransaction {
  id: string;
  userId: string;
  taskId: string;
  amount: number;
  currency: string;
  status: 'pending' | 'completed' | 'failed';
  timestamp: Date;
}

class EarningsService {
  private static instance: EarningsService;

  private constructor() {}

  public static getInstance(): EarningsService {
    if (!EarningsService.instance) {
      EarningsService.instance = new EarningsService();
    }
    return EarningsService.instance;
  }

  async creditEarnings(userId: string, taskId: string): Promise<void> {
    try {
      // Get task details
      const task = await taskService.getTaskById(taskId);
      if (!task) throw new Error('Task not found');

      // Calculate earnings amount
      const earnings = task.socialMediaDetails 
        ? task.budget?.costPerAction || 0
        : task.reward;

      // Create earning transaction
      const transaction: EarningTransaction = {
        id: Date.now().toString(),
        userId,
        taskId,
        amount: earnings,
        currency: task.budget?.currency || 'USD',
        status: 'pending',
        timestamp: new Date()
      };

      // Store transaction
      await this.storeTransaction(transaction);

      // Update user's balance
      await this.updateUserBalance(userId, earnings, task.budget?.currency || 'USD');

      logger.info('Earnings credited successfully', {
        userId,
        taskId,
        amount: earnings
      });
    } catch (error) {
      logger.error('Failed to credit earnings', { error, userId, taskId });
      throw error;
    }
  }

  async getUserEarnings(userId: string): Promise<{
    total: number;
    available: number;
    pending: number;
    currency: string;
  }> {
    try {
      const transactions = await this.getUserTransactions(userId);
      
      const totals = transactions.reduce(
        (acc, tx) => {
          if (tx.status === 'completed') {
            acc.total += tx.amount;
            acc.available += tx.amount;
          } else if (tx.status === 'pending') {
            acc.total += tx.amount;
            acc.pending += tx.amount;
          }
          return acc;
        },
        { total: 0, available: 0, pending: 0 }
      );

      return {
        ...totals,
        currency: transactions[0]?.currency || 'USD'
      };
    } catch (error) {
      logger.error('Failed to get user earnings', { error, userId });
      throw error;
    }
  }

  private async storeTransaction(transaction: EarningTransaction): Promise<void> {
    try {
      const transactions = this.getStoredTransactions();
      transactions.push(transaction);
      localStorage.setItem('earnings_transactions', JSON.stringify(transactions));
    } catch (error) {
      logger.error('Failed to store transaction', { error, transaction });
      throw error;
    }
  }

  private async updateUserBalance(
    userId: string,
    amount: number,
    currency: string
  ): Promise<void> {
    try {
      const balanceKey = `user_balance_${userId}`;
      const currentBalance = parseFloat(localStorage.getItem(balanceKey) || '0');
      const newBalance = currentBalance + amount;
      localStorage.setItem(balanceKey, newBalance.toString());
    } catch (error) {
      logger.error('Failed to update user balance', { error, userId, amount });
      throw error;
    }
  }

  private async getUserTransactions(userId: string): Promise<EarningTransaction[]> {
    try {
      const transactions = this.getStoredTransactions();
      return transactions.filter(tx => tx.userId === userId);
    } catch (error) {
      logger.error('Failed to get user transactions', { error, userId });
      throw error;
    }
  }

  private getStoredTransactions(): EarningTransaction[] {
    try {
      const stored = localStorage.getItem('earnings_transactions');
      return stored ? JSON.parse(stored) : [];
    } catch (error) {
      logger.error('Failed to get stored transactions', { error });
      return [];
    }
  }
}

export const earningsService = EarningsService.getInstance();