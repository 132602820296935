import React, { useState } from 'react';
import { X, CheckCircle, AlertCircle, Settings } from 'lucide-react';
import { Button } from '../shared';
import { TaskSubmission } from '../../types/task';
import { batchApprovalService } from '../../services/BatchApprovalService';
import { useNotifications } from '../../context/NotificationContext';

interface BatchApprovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  submissions: TaskSubmission[];
  onComplete: () => void;
}

export const BatchApprovalModal: React.FC<BatchApprovalModalProps> = ({
  isOpen,
  onClose,
  submissions,
  onComplete
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [results, setResults] = useState<{
    approved: TaskSubmission[];
    rejected: TaskSubmission[];
    needsReview: TaskSubmission[];
    errors: { submission: TaskSubmission; error: string }[];
  } | null>(null);
  const { addNotification } = useNotifications();

  const handleProcess = async () => {
    setIsProcessing(true);
    try {
      const batchResults = await batchApprovalService.processBatchApproval(submissions);
      setResults(batchResults);

      addNotification({
        type: 'success',
        title: 'Batch Processing Complete',
        message: `${batchResults.approved.length} approved, ${batchResults.rejected.length} rejected, ${batchResults.needsReview.length} need review`
      });

      onComplete();
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Batch Processing Failed',
        message: 'Failed to process submissions'
      });
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold">Batch Process Submissions</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-6">
            {!results ? (
              <>
                <div className="bg-indigo-50 p-4 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <Settings className="h-5 w-5 text-indigo-600" />
                    <h3 className="font-medium text-indigo-900">
                      Process {submissions.length} Submissions
                    </h3>
                  </div>
                  <p className="text-sm text-indigo-700">
                    Submissions will be automatically processed based on our verification system.
                    High-confidence submissions will be auto-approved, while others may require
                    manual review.
                  </p>
                </div>

                <div className="flex justify-end gap-3">
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={isProcessing}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleProcess}
                    disabled={isProcessing}
                  >
                    {isProcessing ? 'Processing...' : 'Start Processing'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-green-50 p-4 rounded-lg">
                      <div className="flex items-center gap-2 mb-2">
                        <CheckCircle className="h-5 w-5 text-green-600" />
                        <h3 className="font-medium text-green-900">
                          Auto-Approved
                        </h3>
                      </div>
                      <p className="text-2xl font-bold text-green-600">
                        {results.approved.length}
                      </p>
                    </div>

                    <div className="bg-yellow-50 p-4 rounded-lg">
                      <div className="flex items-center gap-2 mb-2">
                        <AlertCircle className="h-5 w-5 text-yellow-600" />
                        <h3 className="font-medium text-yellow-900">
                          Needs Review
                        </h3>
                      </div>
                      <p className="text-2xl font-bold text-yellow-600">
                        {results.needsReview.length}
                      </p>
                    </div>
                  </div>

                  {results.errors.length > 0 && (
                    <div className="bg-red-50 p-4 rounded-lg">
                      <div className="flex items-center gap-2 mb-2">
                        <AlertCircle className="h-5 w-5 text-red-600" />
                        <h3 className="font-medium text-red-900">
                          Processing Errors
                        </h3>
                      </div>
                      <p className="text-sm text-red-600">
                        {results.errors.length} submissions failed to process
                      </p>
                    </div>
                  )}
                </div>

                <div className="flex justify-end">
                  <Button variant="primary" onClick={onClose}>
                    Close
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};