import axios from 'axios';
import { logger } from '../utils/logger';
import { 
  PaymentMethod, 
  PaymentResponse, 
  WithdrawalRequest 
} from '../types/payment';
import { cacheService } from './CacheService';

class PaymentService {
  private static instance: PaymentService;
  private readonly API_URL = import.meta.env.VITE_API_URL;
  private readonly CACHE_TTL = 5 * 60 * 1000; // 5 minutes

  private constructor() {}

  public static getInstance(): PaymentService {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService();
    }
    return PaymentService.instance;
  }

  async initiateWithdrawal(request: WithdrawalRequest): Promise<PaymentResponse> {
    try {
      logger.info('Initiating withdrawal', { 
        method: request.method, 
        amount: request.amount 
      });

      const response = await axios.post(
        `${this.API_URL}/payments/withdraw`,
        request,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      // Invalidate balance cache
      cacheService.invalidate('balance');

      return response.data;
    } catch (error) {
      logger.error('Withdrawal failed', { error, request });
      throw this.handleError(error);
    }
  }

  async getBalance(): Promise<number> {
    return cacheService.getOrSet(
      'balance',
      'user_balance',
      async () => {
        try {
          const response = await axios.get(`${this.API_URL}/payments/balance`);
          return response.data.balance;
        } catch (error) {
          logger.error('Failed to get balance', { error });
          throw this.handleError(error);
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async getPaymentMethods(): Promise<PaymentMethod[]> {
    return cacheService.getOrSet(
      'payment_methods',
      'available_methods',
      async () => {
        try {
          const response = await axios.get(`${this.API_URL}/payments/methods`);
          return response.data;
        } catch (error) {
          logger.error('Failed to get payment methods', { error });
          throw this.handleError(error);
        }
      },
      { ttl: this.CACHE_TTL }
    );
  }

  async verifyAccount(method: string, accountDetails: Record<string, string>): Promise<boolean> {
    try {
      const response = await axios.post(
        `${this.API_URL}/payments/verify-account`,
        {
          method,
          accountDetails
        }
      );
      return response.data.verified;
    } catch (error) {
      logger.error('Account verification failed', { error });
      throw this.handleError(error);
    }
  }

  private handleError(error: any): Error {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.message || error.message;
      return new Error(message);
    }
    return error;
  }
}

export const paymentService = PaymentService.getInstance();