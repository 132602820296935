import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Wallet, TrendingUp, Award, Clock } from 'lucide-react';

const emptyChartData = [
  { date: 'Mon', earnings: 0 },
  { date: 'Tue', earnings: 0 },
  { date: 'Wed', earnings: 0 },
  { date: 'Thu', earnings: 0 },
  { date: 'Fri', earnings: 0 },
  { date: 'Sat', earnings: 0 },
  { date: 'Sun', earnings: 0 }
];

export const IndividualOverview = () => {
  const stats = [
    {
      icon: Wallet,
      label: 'Available Balance',
      value: '$0.00',
      change: '0%',
      positive: true
    },
    {
      icon: TrendingUp,
      label: 'Tasks Completed',
      value: '0',
      change: '0 tasks',
      positive: true
    },
    {
      icon: Award,
      label: 'Current Level',
      value: '1',
      change: 'Just started',
      positive: true
    },
    {
      icon: Clock,
      label: 'Time Spent',
      value: '0h',
      change: 'No tasks yet',
      positive: true
    }
  ];

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
        <p className="mt-1 text-gray-600">
          Track your earnings and task progress
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat, index) => {
          const Icon = stat.icon;
          return (
            <div
              key={index}
              className="bg-white p-6 rounded-xl shadow-sm"
            >
              <div className="flex items-center justify-between">
                <div className="bg-indigo-50 p-2 rounded-lg">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <span className="text-sm font-medium text-gray-500">
                  {stat.change}
                </span>
              </div>
              <p className="mt-4 text-2xl font-semibold text-gray-900">
                {stat.value}
              </p>
              <p className="mt-1 text-sm text-gray-500">{stat.label}</p>
            </div>
          );
        })}
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">
              Earnings Overview
            </h2>
            <p className="text-sm text-gray-500">
              Start completing tasks to see your earnings grow
            </p>
          </div>
        </div>
        
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={emptyChartData}>
              <defs>
                <linearGradient id="earnings" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4F46E5" stopOpacity={0.1} />
                  <stop offset="95%" stopColor="#4F46E5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="date"
                tick={{ fill: '#6B7280' }}
                tickLine={false}
              />
              <YAxis
                tick={{ fill: '#6B7280' }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `$${value}`}
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="earnings"
                stroke="#4F46E5"
                fillOpacity={1}
                fill="url(#earnings)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        {/* Empty state message */}
        <div className="text-center mt-4">
          <p className="text-gray-500">
            Complete your first task to start tracking your earnings
          </p>
        </div>
      </div>
    </div>
  );
};