import React, { useState } from 'react';
import { X, CheckCircle, XCircle } from 'lucide-react';
import { Button } from '../shared';
import { TaskSubmission } from '../../types/task';

interface ReviewSubmissionModalProps {
  submission: TaskSubmission;
  onClose: () => void;
  onReview: (submissionId: string, approved: boolean, feedback?: string) => void;
}

export const ReviewSubmissionModal: React.FC<ReviewSubmissionModalProps> = ({
  submission,
  onClose,
  onReview
}) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReview = async (approved: boolean) => {
    setIsSubmitting(true);
    try {
      await onReview(submission.id, approved, feedback);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold">Review Submission</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-6">
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Submission Content
              </h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-900">{submission.content}</p>
              </div>
            </div>

            {submission.links && submission.links.length > 0 && (
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  Provided Links
                </h3>
                <div className="space-y-2">
                  {submission.links.map((link, index) => (
                    <a
                      key={index}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-indigo-600 hover:text-indigo-700"
                    >
                      {link}
                    </a>
                  ))}
                </div>
              </div>
            )}

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Feedback
              </h3>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Provide feedback for the worker..."
              />
            </div>

            <div className="flex justify-end gap-3 pt-6 border-t">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => handleReview(false)}
                disabled={isSubmitting}
                className="flex items-center gap-2"
              >
                <XCircle className="h-5 w-5" />
                Reject
              </Button>
              <Button
                variant="primary"
                onClick={() => handleReview(true)}
                disabled={isSubmitting}
                className="flex items-center gap-2"
              >
                <CheckCircle className="h-5 w-5" />
                Approve
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};