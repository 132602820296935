import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../../components/shared';
import { Clock, CheckCircle, AlertCircle, Send, ArrowLeft, Link2 } from 'lucide-react';
import { useNotifications } from '../../context/NotificationContext';
import { taskService } from '../../services/TaskService';
import { Task } from '../../types/task';

export const TaskWorkspace = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [task, setTask] = useState<Task | null>(null);
  const [submission, setSubmission] = useState({
    content: '',
    links: [''],
    attachments: [] as File[]
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);

  useEffect(() => {
    const loadTask = async () => {
      if (!taskId) return;
      const taskData = await taskService.getTaskById(taskId);
      if (taskData) {
        setTask(taskData);
      } else {
        addNotification({
          type: 'error',
          title: 'Task Not Found',
          message: 'The requested task could not be found.'
        });
        navigate('/dashboard/tasks');
      }
    };

    loadTask();

    // Start timer
    const timer = setInterval(() => {
      setTimeSpent(prev => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [taskId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!task || !submission.content.trim()) return;

    setIsSubmitting(true);
    try {
      await taskService.submitTask({
        taskId: task.id,
        content: submission.content,
        links: submission.links.filter(link => link.trim()),
        attachments: submission.attachments,
        timeSpent
      });

      addNotification({
        type: 'success',
        title: 'Task Submitted',
        message: 'Your work has been submitted for review.'
      });

      navigate('/dashboard/tasks');
    } catch (error: any) {
      addNotification({
        type: 'error',
        title: 'Submission Failed',
        message: error.message || 'Failed to submit task. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  if (!task) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/dashboard/tasks')}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{task.title}</h1>
            <p className="text-gray-600">Task #{task.id}</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2 text-gray-600">
            <Clock className="h-5 w-5" />
            <span>Time Spent: {formatTime(timeSpent)}</span>
          </div>
          <div className="flex items-center gap-2 text-green-600">
            <CheckCircle className="h-5 w-5" />
            <span>In Progress</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Task Details */}
        <div className="lg:col-span-1 space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Task Details</h2>
            <div className="space-y-4">
              <div>
                <p className="text-sm font-medium text-gray-500">Description</p>
                <p className="mt-1">{task.description}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Requirements</p>
                <ul className="mt-1 space-y-2">
                  {task.requirements.map((req, index) => (
                    <li key={index} className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span>{req}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="grid grid-cols-2 gap-4 pt-4 border-t">
                <div>
                  <p className="text-sm font-medium text-gray-500">Reward</p>
                  <p className="mt-1 text-lg font-semibold">₦{task.reward.toLocaleString()}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Time Estimate</p>
                  <p className="mt-1">{task.timeEstimate}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Task Progress</h2>
            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-sm mb-1">
                  <span>Time Spent</span>
                  <span>{formatTime(timeSpent)}</span>
                </div>
                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-indigo-600 rounded-full transition-all duration-500"
                    style={{ 
                      width: `${Math.min((timeSpent / (parseFloat(task.timeEstimate) * 3600)) * 100, 100)}%` 
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Submission Form */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Your Work</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Work Details
                </label>
                <textarea
                  value={submission.content}
                  onChange={(e) => setSubmission(prev => ({ ...prev, content: e.target.value }))}
                  rows={8}
                  className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Describe your work and how you completed the requirements..."
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Relevant Links
                </label>
                <div className="space-y-2">
                  {submission.links.map((link, index) => (
                    <div key={index} className="flex gap-2">
                      <div className="relative flex-1">
                        <Link2 className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                          type="url"
                          value={link}
                          onChange={(e) => {
                            const newLinks = [...submission.links];
                            newLinks[index] = e.target.value;
                            setSubmission(prev => ({ ...prev, links: newLinks }));
                          }}
                          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder="https://"
                        />
                      </div>
                      {index === submission.links.length - 1 ? (
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => setSubmission(prev => ({
                            ...prev,
                            links: [...prev.links, '']
                          }))}
                        >
                          Add Link
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => {
                            const newLinks = submission.links.filter((_, i) => i !== index);
                            setSubmission(prev => ({ ...prev, links: newLinks }));
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Attachments
                </label>
                <div className="flex items-center justify-center w-full">
                  <label className="w-full flex flex-col items-center px-4 py-6 bg-white rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:border-indigo-500">
                    <div className="flex items-center justify-center">
                      <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                      </svg>
                    </div>
                    <span className="mt-2 text-sm text-gray-500">
                      Drop files here or click to upload
                    </span>
                    <input 
                      type="file" 
                      className="hidden" 
                      multiple
                      onChange={(e) => {
                        const files = Array.from(e.target.files || []);
                        setSubmission(prev => ({
                          ...prev,
                          attachments: [...prev.attachments, ...files]
                        }));
                      }}
                    />
                  </label>
                </div>
                {submission.attachments.length > 0 && (
                  <div className="mt-4 space-y-2">
                    {submission.attachments.map((file, index) => (
                      <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                        <span className="text-sm text-gray-600">{file.name}</span>
                        <button
                          type="button"
                          onClick={() => {
                            const newAttachments = submission.attachments.filter((_, i) => i !== index);
                            setSubmission(prev => ({ ...prev, attachments: newAttachments }));
                          }}
                          className="text-red-600 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between pt-6 border-t">
                <div className="flex items-center text-yellow-600">
                  <AlertCircle className="h-5 w-5 mr-2" />
                  <span className="text-sm">
                    Make sure your submission meets all requirements
                  </span>
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  className="flex items-center gap-2"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      Submitting...
                    </>
                  ) : (
                    <>
                      <Send className="h-5 w-5" />
                      Submit Task
                    </>
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};