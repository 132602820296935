import React, { createContext, useContext, useEffect, useState } from 'react';
import { onMessage } from 'firebase/messaging';
import { 
  app, 
  db, 
  messaging, 
  initializeFCM,
  isNotificationsSupported,
  getNotificationPermission,
  requestNotificationPermission
} from '../services/firebase';
import { useNotifications } from './NotificationContext';
import { logger } from '../utils/logger';

interface FirebaseContextType {
  app: any;
  db: any;
  messaging: any | null;
  notificationsSupported: boolean;
  notificationPermission: NotificationPermission;
  fcmToken: string | null;
  requestNotificationPermission: () => Promise<boolean>;
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [notificationPermission, setNotificationPermission] = useState<NotificationPermission>(
    getNotificationPermission()
  );
  const { addNotification } = useNotifications();
  const notificationsSupported = isNotificationsSupported();

  useEffect(() => {
    if (notificationsSupported && notificationPermission === 'granted') {
      initializeFCM().then(token => {
        if (token) {
          setFcmToken(token);
          setupMessageHandler();
        }
      });
    }
  }, [notificationsSupported, notificationPermission]);

  const setupMessageHandler = () => {
    if (!messaging) return;

    onMessage(messaging, (payload) => {
      // Add to notification system
      addNotification({
        type: 'info',
        title: payload.notification?.title || 'New Notification',
        message: payload.notification?.body || ''
      });

      // Create a native browser notification if permission is granted
      if (Notification.permission === 'granted') {
        try {
          new Notification(payload.notification?.title || 'New Notification', {
            body: payload.notification?.body,
            icon: '/logo.png'
          });
        } catch (error) {
          logger.warn('Failed to create notification:', error);
        }
      }
    });
  };

  const handleRequestPermission = async () => {
    const granted = await requestNotificationPermission();
    if (granted) {
      setNotificationPermission('granted');
      const token = await initializeFCM();
      if (token) {
        setFcmToken(token);
        setupMessageHandler();
      }
    }
    return granted;
  };

  return (
    <FirebaseContext.Provider value={{
      app,
      db,
      messaging,
      notificationsSupported,
      notificationPermission,
      fcmToken,
      requestNotificationPermission: handleRequestPermission
    }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};