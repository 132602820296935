import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from './shared';
import { ArrowRight } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';

export const Hero = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const handleStartEarning = async () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      await loginWithRedirect({
        appState: { returnTo: '/onboarding' }
      });
    }
  };

  const handleLearnMore = () => {
    document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="pt-32 pb-20">
      <Container>
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl md:text-6xl font-bold tracking-tight text-gray-900 mb-6">
            Turn Tasks into <span className="text-indigo-600">Cash</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Complete engaging tasks, earn real rewards, and level up your earning
            potential in our gamified platform.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button size="lg" variant="primary" onClick={handleStartEarning} className="group">
              Start Earning
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Button>
            <Button size="lg" variant="outline" onClick={handleLearnMore}>
              Learn More
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};