import React, { useState } from 'react';
import { Container } from './shared';
import { ChevronDown, HelpCircle } from 'lucide-react';

const faqs = [
  {
    question: "How do I start earning on ToCash?",
    answer: "Getting started is easy! Simply sign up for an account, complete your profile, and browse available tasks. Choose tasks that match your skills and interests, complete them accurately, and start earning rewards immediately."
  },
  {
    question: "What types of tasks are available?",
    answer: "We offer a diverse range of tasks including content writing, data entry, market research, surveys, user testing, and more. Tasks vary in complexity and reward levels, allowing you to choose based on your expertise and time availability."
  },
  {
    question: "How and when do I get paid?",
    answer: "Payments are processed weekly through your chosen payment method (PayPal, bank transfer, or crypto). Once you reach the minimum threshold of $10, you can request a withdrawal. Most payments are processed within 1-2 business days."
  },
  {
    question: "How does the leveling system work?",
    answer: "Our leveling system rewards consistent quality work. You earn experience points for each completed task, with bonus points for exceptional performance. Higher levels unlock better-paying tasks, bonus multipliers, and special achievements."
  },
  {
    question: "Is ToCash available worldwide?",
    answer: "Yes! ToCash is available to users worldwide. However, available tasks and payment methods may vary by region. We're constantly expanding our global offerings to provide opportunities for everyone."
  },
  {
    question: "What if I need help with a task?",
    answer: "We provide comprehensive task guidelines and our support team is available 24/7. You can also access our community forum where experienced users share tips and best practices. If you're stuck, don't hesitate to reach out!"
  }
];

const FAQItem = ({ 
  question, 
  answer, 
  isOpen, 
  onClick 
}: { 
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
}) => (
  <div className="border-b border-gray-200 last:border-0">
    <button
      className="w-full py-6 text-left flex items-center justify-between gap-4"
      onClick={onClick}
    >
      <span className="text-lg font-medium text-gray-900">{question}</span>
      <ChevronDown
        className={`w-5 h-5 text-gray-500 transition-transform ${
          isOpen ? 'transform rotate-180' : ''
        }`}
      />
    </button>
    <div
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        isOpen ? 'max-h-96 mb-6' : 'max-h-0'
      }`}
    >
      <p className="text-gray-600">{answer}</p>
    </div>
  </div>
);

export const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  return (
    <section className="py-20 bg-gray-50" id="faq">
      <Container>
        <div className="text-center mb-12">
          <div className="flex items-center justify-center gap-2 mb-4">
            <HelpCircle className="w-8 h-8 text-indigo-600" />
            <h2 className="text-3xl font-bold text-gray-900">Frequently Asked Questions</h2>
          </div>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Everything you need to know about earning with ToCash
          </p>
        </div>

        <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-sm">
          <div className="p-6">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              />
            ))}
          </div>
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Still have questions?{' '}
            <a href="#contact" className="text-indigo-600 hover:text-indigo-700 font-medium">
              Contact our support team
            </a>
          </p>
        </div>
      </Container>
    </section>
  );
};