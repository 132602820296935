import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/shared';
import { WithdrawalModal } from '../../../components/WithdrawalModal';
import { ArrowUpRight, ArrowDownRight, Wallet, DollarSign } from 'lucide-react';
import { Transaction } from '../../../types/payment';
import { forexService } from '../../../services/ForexService';
import { transactionService } from '../../../services/TransactionService';
import { usePayment } from '../../../hooks/usePayment';
import { useAuth0 } from '@auth0/auth0-react';

export const Earnings = () => {
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [displayCurrency, setDisplayCurrency] = useState<'USD' | 'NGN'>('USD');
  const { initiateWithdrawal, loading } = usePayment();
  const { user } = useAuth0();
  const [availableBalance, setAvailableBalance] = useState(0);

  const loadTransactions = async () => {
    if (!user?.sub) return;
    try {
      const userTransactions = await transactionService.getUserTransactions(user.sub);
      setTransactions(userTransactions);
      
      // Calculate available balance
      const balance = userTransactions.reduce((total, tx) => {
        if (tx.type === 'earning') {
          return total + tx.amount;
        } else if (tx.type === 'withdrawal') {
          return total - tx.amount;
        }
        return total;
      }, 0);
      
      setAvailableBalance(balance);
    } catch (error) {
      console.error('Failed to load transactions:', error);
    }
  };

  useEffect(() => {
    loadTransactions();
  }, [user]);

  const handleWithdrawalComplete = async () => {
    setShowWithdrawalModal(false);
    await loadTransactions();
  };

  const formatAmount = (amount: number, currency: string) => {
    if (displayCurrency === 'USD') {
      return currency === 'NGN' 
        ? forexService.convertNGNtoUSD(amount).toFixed(2)
        : amount.toFixed(2);
    } else {
      return currency === 'USD'
        ? forexService.convertUSDtoNGN(amount).toLocaleString()
        : amount.toLocaleString();
    }
  };

  const getTotalEarned = () => {
    const total = transactions.reduce((sum, tx) => {
      if (tx.type === 'earning') {
        const amountInUSD = tx.currency === 'NGN' 
          ? forexService.convertNGNtoUSD(tx.amount)
          : tx.amount;
        return sum + amountInUSD;
      }
      return sum;
    }, 0);

    return displayCurrency === 'USD' 
      ? total.toFixed(2)
      : forexService.convertUSDtoNGN(total).toLocaleString();
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Earnings</h1>
          <p className="mt-1 text-gray-600">
            Manage your earnings and withdrawals
          </p>
        </div>
        <Button
          variant="primary"
          onClick={() => setShowWithdrawalModal(true)}
          className="flex items-center gap-2"
          disabled={availableBalance <= 0}
        >
          <Wallet className="h-5 w-5" />
          Withdraw Earnings
        </Button>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <div>
            <p className="text-sm text-gray-500">Available Balance</p>
            <p className="text-3xl font-bold text-gray-900">
              {displayCurrency === 'USD' 
                ? `$${availableBalance.toFixed(2)}`
                : `₦${forexService.convertUSDtoNGN(availableBalance).toLocaleString()}`
              }
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-500">Total Earned</p>
            <p className="text-xl font-semibold text-gray-900">
              {displayCurrency === 'USD' ? '$' : '₦'}{getTotalEarned()}
            </p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-gray-900">
              Recent Transactions
            </h3>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setDisplayCurrency(prev => prev === 'USD' ? 'NGN' : 'USD')}
              className="flex items-center gap-2"
            >
              <DollarSign className="h-4 w-4" />
              Show in {displayCurrency === 'USD' ? 'NGN' : 'USD'}
            </Button>
          </div>

          <div className="space-y-4">
            {transactions.length > 0 ? (
              transactions.map((transaction) => (
                <div
                  key={transaction.id}
                  className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50"
                >
                  <div className="flex items-center gap-3">
                    <div className={`p-2 rounded-lg ${
                      transaction.type === 'earning' 
                        ? 'bg-green-100' 
                        : 'bg-blue-100'
                    }`}>
                      {transaction.type === 'earning' ? (
                        <ArrowDownRight className="h-4 w-4 text-green-600" />
                      ) : (
                        <ArrowUpRight className="h-4 w-4 text-blue-600" />
                      )}
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">
                        {transaction.description}
                      </p>
                      <div className="flex items-center gap-2 text-sm text-gray-600">
                        <span>{new Date(transaction.timestamp).toLocaleString()}</span>
                        {transaction.method && (
                          <>
                            <span>•</span>
                            <span className="capitalize">
                              {transaction.method.replace('_', ' ')}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <span className={`font-medium ${
                      transaction.type === 'earning' 
                        ? 'text-green-600' 
                        : 'text-blue-600'
                    }`}>
                      {transaction.type === 'earning' ? '+' : '-'}
                      {displayCurrency === 'USD' ? '$' : '₦'}
                      {formatAmount(transaction.amount, transaction.currency)}
                    </span>
                    {transaction.fee && (
                      <p className="text-xs text-gray-500">
                        Fee: {displayCurrency === 'USD' ? '$' : '₦'}
                        {formatAmount(transaction.fee, transaction.currency)}
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No transactions yet
              </div>
            )}
          </div>
        </div>
      </div>

      <WithdrawalModal
        isOpen={showWithdrawalModal}
        onClose={() => setShowWithdrawalModal(false)}
        availableBalance={availableBalance}
        onSuccess={handleWithdrawalComplete}
      />
    </div>
  );
};