import React from 'react';
import { Container } from './shared';
import { 
  Shield, 
  Lock, 
  CheckCircle, 
  UserCheck, 
  CreditCard,
  Award,
  AlertCircle,
  Eye
} from 'lucide-react';

const securityFeatures = [
  {
    icon: Shield,
    title: 'Secure Platform',
    description: 'Enterprise-grade security with 256-bit encryption and continuous monitoring'
  },
  {
    icon: Lock,
    title: 'Data Protection',
    description: 'GDPR compliant with strict data privacy controls and regular security audits'
  },
  {
    icon: CreditCard,
    title: 'Payment Protection',
    description: 'Secure payment processing with fraud detection and buyer protection'
  },
  {
    icon: UserCheck,
    title: 'Identity Verification',
    description: 'Multi-step verification process for all users and businesses'
  }
];

const trustBadges = [
  {
    icon: Award,
    label: 'ISO 27001 Certified',
    description: 'International security standard certification'
  },
  {
    icon: Shield,
    label: 'PCI DSS Compliant',
    description: 'Secure payment data handling'
  },
  {
    icon: CheckCircle,
    label: 'GDPR Compliant',
    description: 'European data protection standards'
  }
];

const guarantees = [
  {
    title: 'Money-Back Guarantee',
    description: 'Unsatisfied with a completed task? Get your money back within 14 days.',
    icon: CreditCard
  },
  {
    title: 'Quality Assurance',
    description: 'All tasks undergo quality checks before payment release.',
    icon: CheckCircle
  },
  {
    title: 'Dispute Resolution',
    description: '24/7 support team to handle any issues or concerns.',
    icon: AlertCircle
  },
  {
    title: 'Transparent Pricing',
    description: 'No hidden fees or charges. Clear pricing for all services.',
    icon: Eye
  }
];

export const TrustSecurity = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50" id="trust">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Trust & Security
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Your security is our top priority. We maintain the highest standards of platform safety.
          </p>
        </div>

        {/* Security Features */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {securityFeatures.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="bg-indigo-50 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            );
          })}
        </div>

        {/* Trust Badges */}
        <div className="bg-white rounded-2xl shadow-sm p-8 mb-16">
          <h3 className="text-xl font-semibold text-gray-900 text-center mb-8">
            Industry-Leading Security Standards
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            {trustBadges.map((badge, index) => {
              const Icon = badge.icon;
              return (
                <div
                  key={index}
                  className="flex items-center gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <div className="bg-indigo-50 p-3 rounded-lg">
                    <Icon className="h-8 w-8 text-indigo-600" />
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900">{badge.label}</p>
                    <p className="text-sm text-gray-600">{badge.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Guarantees */}
        <div className="bg-indigo-600 rounded-2xl p-8 text-white">
          <h3 className="text-xl font-semibold text-center mb-8">
            Our Protection Guarantees
          </h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {guarantees.map((guarantee, index) => {
              const Icon = guarantee.icon;
              return (
                <div key={index} className="text-center">
                  <div className="bg-white/10 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                    <Icon className="h-6 w-6 text-white" />
                  </div>
                  <h4 className="font-semibold mb-2">{guarantee.title}</h4>
                  <p className="text-sm text-indigo-100">{guarantee.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Additional Info */}
        <div className="mt-16 text-center">
          <p className="text-gray-600 max-w-2xl mx-auto">
            Have questions about our security measures?{' '}
            <a href="#contact" className="text-indigo-600 hover:text-indigo-700 font-medium">
              Contact our security team
            </a>{' '}
            or visit our{' '}
            <a href="#security" className="text-indigo-600 hover:text-indigo-700 font-medium">
              Security Center
            </a>
          </p>
        </div>
      </Container>
    </section>
  );
};