import { CreditCard, Wallet, DollarSign } from 'lucide-react';
import { PaymentMethod, PaymentMethodConfig } from '../types/payment';

export const paymentConfigs: Record<PaymentMethod, PaymentMethodConfig> = {
  bank_transfer: {
    id: 'bank_transfer',
    name: 'Bank Transfer',
    icon: CreditCard,
    fields: ['account_number', 'bank_name', 'account_name'],
    minAmount: 5000,
    maxAmount: 1000000,
    dailyLimit: 100000,
    monthlyLimit: 2000000,
    currency: 'NGN',
    verificationRequired: true,
    processingFee: {
      percentage: 0.5,
      fixed: 100
    },
    processingTime: '1-2 business days',
    autoPayoutEnabled: true,
    autoPayoutThreshold: 10000
  },
  opay: {
    id: 'opay',
    name: 'OPay',
    icon: Wallet,
    fields: ['account_number', 'account_name'],
    minAmount: 1000,
    maxAmount: 500000,
    dailyLimit: 50000,
    monthlyLimit: 1000000,
    currency: 'NGN',
    verificationRequired: true,
    processingFee: {
      percentage: 0.3,
      fixed: 50
    },
    processingTime: 'Instant',
    autoPayoutEnabled: true,
    autoPayoutThreshold: 5000
  },
  moniepoint: {
    id: 'moniepoint',
    name: 'Moniepoint',
    icon: Wallet,
    fields: ['account_number', 'account_name'],
    minAmount: 1000,
    maxAmount: 500000,
    dailyLimit: 50000,
    monthlyLimit: 1000000,
    currency: 'NGN',
    verificationRequired: true,
    processingFee: {
      percentage: 0.3,
      fixed: 50
    },
    processingTime: 'Instant',
    autoPayoutEnabled: true,
    autoPayoutThreshold: 5000
  },
  paypal: {
    id: 'paypal',
    name: 'PayPal (USD)',
    icon: DollarSign,
    fields: ['paypal_email'],
    minAmount: 10,
    maxAmount: 1000,
    dailyLimit: 500,
    monthlyLimit: 10000,
    currency: 'USD',
    verificationRequired: true,
    processingFee: {
      percentage: 2.5,
      fixed: 0
    },
    processingTime: '1-2 business days',
    autoPayoutEnabled: true,
    autoPayoutThreshold: 50
  }
};

class PaymentConfigService {
  private static instance: PaymentConfigService;

  private constructor() {}

  public static getInstance(): PaymentConfigService {
    if (!PaymentConfigService.instance) {
      PaymentConfigService.instance = new PaymentConfigService();
    }
    return PaymentConfigService.instance;
  }

  getConfig(method: PaymentMethod): PaymentMethodConfig {
    return paymentConfigs[method];
  }

  getAllConfigs(): Record<PaymentMethod, PaymentMethodConfig> {
    return paymentConfigs;
  }

  calculateFee(method: PaymentMethod, amount: number): number {
    const config = this.getConfig(method);
    return (amount * config.processingFee.percentage / 100) + config.processingFee.fixed;
  }

  async checkWithdrawalLimits(
    userId: string,
    method: PaymentMethod,
    amount: number
  ): Promise<{ allowed: boolean; reason?: string }> {
    const config = this.getConfig(method);
    
    if (amount < config.minAmount) {
      return {
        allowed: false,
        reason: `Minimum withdrawal amount is ${config.currency === 'NGN' ? '₦' : '$'}${config.minAmount.toLocaleString()}`
      };
    }

    if (amount > config.maxAmount) {
      return {
        allowed: false,
        reason: `Maximum withdrawal amount is ${config.currency === 'NGN' ? '₦' : '$'}${config.maxAmount.toLocaleString()}`
      };
    }

    return { allowed: true };
  }
}

export const paymentConfigService = PaymentConfigService.getInstance();