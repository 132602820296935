import { Transaction } from '../types/payment';
import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  orderBy, 
  getDocs,
  serverTimestamp 
} from 'firebase/firestore';
import { logger } from '../utils/logger';

class TransactionService {
  private static instance: TransactionService;
  private readonly TRANSACTIONS_COLLECTION = 'transactions';

  private constructor() {}

  public static getInstance(): TransactionService {
    if (!TransactionService.instance) {
      TransactionService.instance = new TransactionService();
    }
    return TransactionService.instance;
  }

  async addTransaction(transaction: Omit<Transaction, 'id' | 'timestamp'>): Promise<Transaction> {
    try {
      const docRef = await addDoc(collection(db, this.TRANSACTIONS_COLLECTION), {
        ...transaction,
        timestamp: serverTimestamp()
      });

      logger.info('Transaction added', { transactionId: docRef.id });

      return {
        id: docRef.id,
        ...transaction,
        timestamp: new Date().toISOString()
      };
    } catch (error) {
      logger.error('Failed to add transaction', { error });
      throw error;
    }
  }

  async getUserTransactions(userId: string): Promise<Transaction[]> {
    try {
      const q = query(
        collection(db, this.TRANSACTIONS_COLLECTION),
        where('userId', '==', userId),
        orderBy('timestamp', 'desc')
      );

      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().toISOString()
      })) as Transaction[];
    } catch (error) {
      logger.error('Failed to get user transactions', { error });
      throw error;
    }
  }

  async getTransactionById(id: string): Promise<Transaction | null> {
    try {
      const q = query(
        collection(db, this.TRANSACTIONS_COLLECTION),
        where('id', '==', id)
      );
      
      const snapshot = await getDocs(q);
      if (snapshot.empty) return null;

      const doc = snapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().toISOString()
      } as Transaction;
    } catch (error) {
      logger.error('Failed to get transaction', { error });
      throw error;
    }
  }
}

export const transactionService = TransactionService.getInstance();