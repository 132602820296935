import React, { useState, useEffect } from 'react';
import { VirtualizedTaskList } from './VirtualizedTaskList';
import { TaskFilters } from './TaskFilters';
import { Task } from '../../types/task';
import { taskService } from '../../services/TaskService';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { Button } from '../shared';
import { useNotifications } from '../../context/NotificationContext';
import { ChevronLeft, ChevronRight } from 'lucide-react';

type TaskListProps = {
  onAcceptTask?: (taskId: string) => void;
};

export const TaskList: React.FC<TaskListProps> = ({ onAcceptTask }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const { addNotification } = useNotifications();
  const [filters, setFilters] = useState({
    searchTerm: '',
    category: '',
    difficulty: '',
    minReward: 0,
    maxReward: 0,
    status: [] as string[]
  });

  const pageSize = 10;

  useEffect(() => {
    loadTasks();
  }, [page, filters]);

  const loadTasks = async () => {
    try {
      setIsLoading(true);
      const response = await taskService.getAvailableTasks(
        page,
        pageSize,
        lastDoc,
        filters
      );
      
      setTasks(response.items);
      setTotalPages(Math.ceil(response.total / pageSize));
      setHasMore(response.hasMore);
      setLastDoc(response.lastDoc);
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to load tasks. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters);
    setPage(1);
    setLastDoc(null);
  };

  const handleAcceptTask = async (taskId: string) => {
    if (!onAcceptTask) return;

    try {
      await onAcceptTask(taskId);
      addNotification({
        type: 'success',
        title: 'Task Accepted',
        message: 'You can now start working on this task.'
      });
      loadTasks();
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to accept task. Please try again.'
      });
    }
  };

  if (isLoading && tasks.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className="space-y-6">
      <TaskFilters
        onFilterChange={handleFilterChange}
        onReset={() => {
          setFilters({
            searchTerm: '',
            category: '',
            difficulty: '',
            minReward: 0,
            maxReward: 0,
            status: []
          });
          setPage(1);
          setLastDoc(null);
        }}
      />

      <VirtualizedTaskList
        tasks={tasks}
        onAcceptTask={handleAcceptTask}
      />

      {tasks.length === 0 && !isLoading && (
        <div className="text-center py-12">
          <p className="text-gray-500">No tasks found matching your criteria.</p>
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <Button
              variant="secondary"
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              Previous
            </Button>
            <Button
              variant="secondary"
              onClick={() => setPage(page + 1)}
              disabled={!hasMore}
            >
              Next
            </Button>
          </div>
          
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{((page - 1) * pageSize) + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {Math.min(page * pageSize, tasks.length)}
                </span>{' '}
                of{' '}
                <span className="font-medium">{totalPages * pageSize}</span> results
              </p>
            </div>
            
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <Button
                  variant="secondary"
                  className="relative inline-flex items-center rounded-l-md px-2 py-2"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                </Button>
                
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                  <Button
                    key={pageNum}
                    variant={pageNum === page ? 'primary' : 'secondary'}
                    className="relative inline-flex items-center px-4 py-2"
                    onClick={() => setPage(pageNum)}
                  >
                    {pageNum}
                  </Button>
                ))}
                
                <Button
                  variant="secondary"
                  className="relative inline-flex items-center rounded-r-md px-2 py-2"
                  onClick={() => setPage(page + 1)}
                  disabled={!hasMore}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                </Button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};