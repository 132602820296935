import React, { useState, useEffect } from 'react';
import { Clock, Edit } from 'lucide-react';
import { Button } from '../shared';
import { Task } from '../../types/task';
import { taskService } from '../../services/TaskService';

interface TaskEditWindowProps {
  task: Task;
  onEdit: () => void;
}

export const TaskEditWindow: React.FC<TaskEditWindowProps> = ({ task, onEdit }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const loadTimeLeft = async () => {
      const remaining = await taskService.getTimeUntilOpen(task.id);
      setTimeLeft(remaining);
    };

    loadTimeLeft();

    const interval = setInterval(() => {
      setTimeLeft(prev => Math.max(0, prev - 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [task.id]);

  if (timeLeft === 0 || task.status !== 'pending') return null;

  return (
    <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Clock className="h-5 w-5 text-yellow-600" />
          <div>
            <p className="font-medium text-yellow-800">
              Edit Window Active
            </p>
            <p className="text-sm text-yellow-600">
              {Math.ceil(timeLeft / 1000)} seconds left to edit this task
            </p>
          </div>
        </div>
        <Button
          variant="secondary"
          size="sm"
          onClick={onEdit}
          className="flex items-center gap-2"
        >
          <Edit className="h-4 w-4" />
          Edit Task
        </Button>
      </div>
    </div>
  );
};